import Vue from "vue";
import axios from "axios";
import vueaxios from "vue-axios";
import App from "./App.vue";
import VueRouter from "vue-router";
import store from "./store/index";
import router from "./router/routes";
import mixin from "./helpers/helper";
import VueMeta from 'vue-meta'
import Sidebar from './layouts/Sidebar'
import Toolbar from './layouts/Toolbar'
import Pagination from 'vue-pagination-2';
import VeeValidate from "vee-validate";
import i18n from "./i18n";
// Import this component
import Trumbowyg from 'vue-trumbowyg';
  
// Import editor css
import 'trumbowyg/dist/ui/trumbowyg.css';
import { Notyf } from 'notyf';
const notyf = new Notyf();

import 'notyf/notyf.min.css';
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import excel from 'vue-excel-export'


Vue.component('sidebar', Sidebar)
Vue.component('toolbar', Toolbar)
Vue.component('pagination', Pagination);
let path = process.env.VUE_APP_BASE_URL;
Vue.prototype.$path = path;
Vue.prototype.$notyf = notyf;
Vue.use(VeeValidate);
Vue.use(VueMeta)
Vue.use(vueaxios, axios);
Vue.use(VueRouter);
Vue.mixin(mixin);
Vue.use(excel);
Vue.use(Trumbowyg);
new Vue({
  axios,
  vueaxios,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

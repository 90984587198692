
export default {
    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const minDate = new Date(today)
        minDate.setFullYear(minDate.getFullYear() - 100)
        const maxDate = new Date(today)
        maxDate.setFullYear(today.getFullYear() + 2)

        return {
            minDate: minDate,
            todayDate: now,
            maxDate: maxDate
        }
    },
    methods: {
        onlyNumber: function ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        }
    }
}
<template>
  <div id="app-onboarding" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-navbar-menu"
    data-mobile-item="#home-sidebar-menu-mobile" :class="{ 'is-pushed-full': $store.state.isSubmenuActive }">
    <div class="page-content-wrapper">
      <div class="page-content is-relative">
        <div class="page-title has-text-centered tabs-wrapper">
          <!-- Sidebar Trigger -->
          <sidebar />

          <div class="title-wrap">
            <h1 class="title is-4">Profile</h1>
          </div>
        </div>
        <div class="page-content-inner">
          <!--Form Layout 1-->
          <form id="admin-form" data-vv-scope="admin-form" v-on:submit.prevent="onSubmit('admin-form')">
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Update Profile</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button type="submit" id="save-button" class="button h-button is-primary is-raised"
                          :class="{ 'is-loading': buttonloader }">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-6">
                        <div class="field">
                          <label>First Name *</label>
                          <div class="control has-icon has-validation" :class="{
                            'has-error': errors.has('admin-form.name'),
                          }">
                            <input type="text" class="input" name="name" data-vv-as="first name" v-model="admin.name"
                              placeholder="" v-validate="'required|min:2|max:50'" :class="{
                                input: true,
                                'is-danger': errors.has(
                                  'admin-form.name'
                                ),
                              }" />
                            <div class="form-icon">
                              <i class="lnir lnir-user-alt"></i>
                            </div>
                            <span v-show="errors.has('admin-form.name')" class="error-text">{{
                              errors.first("admin-form.name") }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Last Name *</label>
                          <div class="control has-icon has-validation" :class="{
                            'has-error': errors.has('admin-form.surname'),
                          }">
                            <input type="text" class="input" name="surname" data-vv-as="first name"
                              v-model="admin.surname" placeholder="" v-validate="'required|min:1|max:50'" :class="{
                                input: true,
                                'is-danger': errors.has('admin-form.surname'),
                              }" />
                            <div class="form-icon">
                              <i class="lnir lnir-user-alt"></i>
                            </div>
                            <span v-show="errors.has('admin-form.surname')" class="error-text">{{
                              errors.first("admin-form.surname") }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="field">
                          <label>Email Address *</label>
                          <div class="control has-icon has-validation" @input="checkMail()" :class="{
                            'has-error': errors.has('admin-form.email'),
                          }">
                            <input type="text" class="input" name="email" v-model="admin.email" placeholder="" readonly
                              disabled v-validate="'required|email|max:50'" :class="{
                                input: true,
                                'is-danger': errors.has('admin-form.email'),
                              }" />
                            <div class="form-icon">
                              <i class="lnir lnir-envelope"></i>
                            </div>
                            <span v-show="errors.has('admin-form.email')" class="error-text">{{
                              errors.first("admin-form.email") }}</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import dataProcess from '../../util/dataProcess';
export default {
  name: "UpdateProfile",
  data() {
    return {
      admin: {},
      buttonloader: false
    };
  },

  mounted() {
    const data = {
      id: this.$store.state.loginUserData.id,
    };
    this.$store.state.isDetailLoading = true;
    dataProcess
      .getData(this.$path + "api/myprofile")
      .then((response) => {
        if (response.status == 200) {
          if (response.data) {
            this.admin = response.data.data;
          }
          this.$store.state.isDetailLoading = false;
        } else {
          this.$notyf.error(response.data);
          this.code = null;
          this.$store.state.isDetailLoading = false;
        }
      });
  },
  methods: {
    onSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        this.$notyf.dismissAll();
        if (result) {
          this.$store.state.isDetailLoading = true;
          this.buttonloader = true;
          let postData = { ...this.admin };
          delete postData.id;
          postData.updatedby = 0;
          dataProcess
            .postData(this.$path + "api/updateprofile", this.admin)
            .then((response) => {
              if (response.status == 200) {
                if (response.data) {
                  this.$store.commit('updateLoginUser', this.admin)
                  this.buttonloader = false;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.success("Admin user updated successfully");
                } else {
                  this.buttonloader = false;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error("Error in inserting data");
                }
              }
              if (response.status == 422 || response.status == 500) {
                this.buttonloader = false;

                this.$store.state.isDetailLoading = false;
                this.$notyf.error(response.data);
              }
            });
        } else {
          this.buttonloader = false;

          this.$notyf.error("Correct form errors!!");
        }
      });
    },
  },
};
</script>

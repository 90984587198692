<template>
  <div class="toolbar ml-auto">
    <div class="toolbar-link">
      <label class="dark-mode ml-auto">
        <input type="checkbox" :checked="selectedDevice == 'desktop' ? 'checked' : ''"
          @click="updateDevice(selectedDevice == 'desktop' ? 'mobile' : 'desktop')">
          <img class="device" :src="'img/' + this.selectedDevice + '.png'" alt="">
      </label>
    </div>
    <a class="toolbar-link right-panel-trigger" @click="languageActive = !languageActive">
      <img :src="'img/lang/' + this.selectedLang + '.svg'" alt="">
    </a>

    <!--Languages panel-->
    <div id="languages-panel" class="right-panel-wrapper is-languages" :class="{ 'is-active': languageActive }">
      <div class="panel-overlay"></div>

      <div class="right-panel">
        <div class="right-panel-head">
          <h3>Select Language</h3>
          <a class="close-panel" @click="languageActive = !languageActive">
            <i class="lnil lnil-chevron-right"></i>
          </a>
        </div>
        <div class="right-panel-body has-slimscroll">
          <div class="languages-boxes">
            <div class="language-box" v-for="data in this.languages" :key="data.id">
              <div class="language-option">
                <input type="radio" v-model="$i18n.locale" :value="data.short_name" @click="updateLanguage(data)"
                  name="language_selection" :checked="selectedLang == data.short_name">
                <div class="language-option-inner">
                  <img :src="'img/lang/' + data.short_name + '.svg'" alt="">
                  <div class="indicator">
                    <i lass="lnil lnil-check"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="img-wrap has-text-centered">
            <img class="light-image" :src="'img/lang/' + this.selectedLang + '.svg'" alt="">
          </div>
        </div>
      </div>
    </div>

    <!--Circular menu-->
    <div id="circular-menu" class="circular-menu" :class="{ 'is-active': scroll > 80 }">

      <a class="floating-btn" onclick="document.getElementById('circular-menu').classList.toggle('active');">
        <i aria-hidden="true" class="fas fa-bars"></i>
        <i aria-hidden="true" class="fas fa-times"></i>
      </a>

      <div class="items-wrapper">
        <a class="menu-item is-flex right-panel-trigger" @click="languageActive = !languageActive">
          <img class="light-image" :src="'img/lang/' + this.selectedLang + '.svg'" alt="">
        </a>
      </div>

    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Toolbar",
  data() {
    return {
      languageActive: false,
      scroll: 0,
      selectedLang: 'en',
      selectedDevice: 'desktop',
      languages: []
    };
  },
  created() {
    this.selectedLang = this.$store.state.appLocale.lang;
    this.selectedDevice = this.$store.state.appDevice.device;
    axios
      .get(this.$path + "api/languages/active-list", {
        headers: { 'Authorization': 'Bearer ' + this.$store.state.loginUserData.access_token }
      })
      .then((response) => {
        if (response.status == 200) {
          if (response.data) {
            this.languages = response.data.data;
          }
          this.$store.state.isDetailLoading = false;
        } else {
          this.total = 0;
        }
      });
  },
  mounted: function () {
    window.addEventListener('scroll', this.currentScroll)
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.currentScroll)
  },

  methods: {
    currentScroll: function () {
      this.scroll = window.scrollY;
    },
    updateLanguage: function (data) {
      this.$store.commit("updateLanguage", { 'lang': data.short_name, 'langId': data.code });
      this.selectedLang = data.short_name;
    },
    updateDevice: function (device) {
      this.$store.commit("updateDevice", { 'device': device, id: device == 'desktop' ? 1 : 2 });
      this.selectedDevice = device;
      console.log(this.selectedDevice);
    }
  },

};
</script>
<style scoped>
.dark-mode input:checked+span:before {
  background: #ffb62e;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: background-color 0.3s ease 0.1s;
  transition: background-color 0.3s ease 0.1s;
}

.dark-mode input:checked+span:after {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: -webkit-transform 0.5s ease 0.15s;
  transition: -webkit-transform 0.5s ease 0.15s;
  transition: transform 0.5s ease 0.15s;
  transition: transform 0.5s ease 0.15s, -webkit-transform 0.5s ease 0.15s;
}

.dark-mode input+span:before {
  content: "";
  width: inherit;
  height: inherit;
  border-radius: inherit;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.dark-mode input+span:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-box-shadow: 0 -23px 0 #ffb62e, 0 23px 0 #ffb62e, 23px 0 0 #ffb62e, -23px 0 0 #ffb62e, 15px 15px 0 #ffb62e, -15px 15px 0 #ffb62e, 15px -15px 0 #ffb62e, -15px -15px 0 #ffb62e;
  box-shadow: 0 -23px 0 #ffb62e, 0 23px 0 #ffb62e, 23px 0 0 #ffb62e, -23px 0 0 #ffb62e, 15px 15px 0 #ffb62e, -15px 15px 0 #ffb62e, 15px -15px 0 #ffb62e, -15px -15px 0 #ffb62e;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dark-mode input:checked+span {
  -webkit-box-shadow: inset 32px -32px 0 0 #ffd22e;
  box-shadow: inset 32px -32px 0 0 #ffd22e;
  -webkit-transform: scale(0.5) rotate(0deg);
  transform: scale(0.5) rotate(0deg);
  -webkit-transition: -webkit-transform 0.3s ease 0.1s, -webkit-box-shadow 0.2s ease 0s;
  transition: -webkit-transform 0.3s ease 0.1s, -webkit-box-shadow 0.2s ease 0s;
  transition: transform 0.3s ease 0.1s, box-shadow 0.2s ease 0s;
  transition: transform 0.3s ease 0.1s, box-shadow 0.2s ease 0s, -webkit-transform 0.3s ease 0.1s, -webkit-box-shadow 0.2s ease 0s;
}</style>
import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";


Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loginUserData: localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : {},
    isAuthenticated: localStorage.getItem("isAuthenticated")
      ? JSON.parse(localStorage.getItem("isAuthenticated"))
      : false,
    isSubmenuActive: false,
    subMenu: false,
    mobileMenu: false,
    isDetailLoading: false,
    dropdown: false,
    token: localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : {},
    appLocale: localStorage.getItem("appLocale")
      ? JSON.parse(localStorage.getItem("appLocale"))
      : { lang: 'en', langId: 'l3' },
    appDevice: localStorage.getItem("appDevice")
      ? JSON.parse(localStorage.getItem("appDevice"))
      : { device: 'desktop', id: 1 },
  },
  mutations: {

    updateLoginUser(state, data) {
      state.loginUserData = data;
      localStorage.setItem("currentUser", JSON.stringify(data));
    },
    updateLoginToken(state, data) {
      state.token = data;
      localStorage.setItem("token", JSON.stringify(data));
    },
    updateLanguage(state, data) {
      state.appLocale = data;
      localStorage.setItem("appLocale", JSON.stringify(data));
    },
    updateDevice(state, data) {
      state.appDevice = data;
      localStorage.setItem("appDevice", JSON.stringify(data));
    },
    isLogin(state, data) {
      state.isAuthenticated = data;
    },
  },

  getters: {
  },

  actions: {
    logout() {
      store.state.mobileMenu = false;
      localStorage.removeItem("currentUser");
      store.state.isAuthenticated = false;
      localStorage.setItem("isAuthenticated", false);
    }
  },
});

export default store;

<template>
  <div id="app-onboarding" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-navbar-menu"
    data-mobile-item="#home-sidebar-menu-mobile" :class="{ 'is-pushed-full': $store.state.isSubmenuActive }">
    <div class="page-content-wrapper">
      <div class="page-content is-relative">
        <div class="page-title has-text-centered tabs-wrapper">
          <!-- Sidebar Trigger -->
          <sidebar />

          <div class="title-wrap">
            <h1 class="title is-4">Manage Clients </h1>
          </div>
          <toolbar />
        </div>

        <div class="datatable-toolbar">
          <div class="select">
            <select class="datatable-filter datatable-select form-control" data-filter="position"
              data-filter-type="default" v-model="perPage" @change="filterData">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          &nbsp;entries per page

          <div class="buttons">
            <div class="control mr-2">
              <input v-model="searchText" @keyup="filterData" class="input custom-text-filter" placeholder="Search..." />
              <div class="form-icon">
                <i data-feather="search"></i>
              </div>
            </div>
            <button class="button h-button is-primary is-elevated" @click="addEdit('add', '')">
              <span class="icon">
                <i aria-hidden="true" class="fas fa-plus"></i>
              </span>
              <span>Add</span>
            </button>
          </div>
        </div>

        <div class="page-content-inner">
          <!-- Datatable -->
          <div class="table-wrapper" data-simplebar>
            <table id="users-datatable" class="table is-datatable is-hoverable table-is-bordered">
              <thead>
                <tr>
                  <th>
                    <div class="control">#</div>
                  </th>
                  <th>Name</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <draggable v-model="clients" tag="tbody" @change="updateOrder">
                <tr data-id="0" v-for="(data, index) in this.clients" :key="data.id">
                  <td>
                    <div class="control">
                      {{ parseInt(perPage) * (currentPage - 1) + index + 1 }}
                    </div>
                  </td>
                  <td class="wrap">
                    <img width="50" v-if="data.client_logo && (typeof data.client_logo == 'string')"
                      :src="data.client_logo" class="pl-2" style="background-color: grey;">
                    <span class="
                                                                    has-dark-text
                                                                    dark-inverted
                                                                    is-font-alt is-weight-600
                                                                    rem-90
                                                                  ">
                      {{ data.client_name }}
                    </span>
                  </td>
                  <td>
                    <div class="field is-grouped">
                      <div class="control">
                        <div class="thin-switch" :class="data.is_active == 0 ? 'is-danger' : 'is-success'">
                          <input :id="'thin-switch' + index" class="input" type="checkbox" :checked="data.is_active == 1"
                            @click="updateStatusOrder(index, { 'is_active': data.is_active == 0 ? 1 : 0, 'client_id': data.client_id })" />
                          <label :for="'thin-switch' + index" class="slider"></label> {{
                            data.is_active == 0 ? "Inactive" : "Active"
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="buttons">
                      <button class="
                                                                      button
                                                                      is-light is-circle
                                                                      hint--bubble hint--success hint--top
                                                                    " data-hint="Edit" @click="addEdit('edit', index)">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>

                      <button @click="deleteClient(index)" class="
                                                                      button
                                                                      is-light is-circle
                                                                      hint--bubble hint--primary hint--top
                                                                    " data-hint="Delete">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
            <div class="section-placeholder" v-if="this.clients.length == 0">
              <div class="placeholder-content">
                <img class="light-image" src="img/illustrations/placeholders/search-4.svg" alt="">
                <img class="dark-image" src="img/illustrations/placeholders/search-4-dark.svg" alt="">
                <h3 class="dark-inverted">No data to show</h3>
                <p>There is currently no data to show in this list.</p>
              </div>
            </div>
          </div>

          <div id="paging-first-datatable" v-if="this.clients.length != 0" class="
                                                          pagination
                                                          datatable-pagination
                                                          pagination-datatables
                                                          text-center
                                                        ">
            <pagination v-model="currentPage" :per-page="parseInt(perPage)" :records="total" @paginate="filterData" />
          </div>
        </div>
      </div>
    </div>
    <div id="add-edit-modal" class="modal h-modal" :class="{ 'is-active': this.show != 0 }">
      <div class="modal-background h-modal-close"></div>
      <div class="modal-content">
        <form id="client-form" data-vv-scope="client-form" v-on:submit.prevent="onSubmit('client-form')">
          <div class="modal-card">
            <header class="modal-card-head">
              <h3>
                {{
                  this.type == "add" ? "Add Client Details" : "Edit Client Details"
                }}
              </h3>
              <button class="h-modal-close ml-auto" aria-label="close" type="button">
                <i class="lnil lnil-close" @click="show = 0;"></i>
              </button>
            </header>
            <div class="modal-card-body">
              <div class="form-body">
                <!--Fieldset-->
                <div class="form-fieldset">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <div class="field">
                        <label>Client Name *</label>
                        <div class="control has-validation" :class="{
                          'has-error': errors.has('client-form.client_name'),
                        }">
                          <input type="text" class="input" name="client_name" v-model="client.client_name" placeholder=""
                            v-validate="'required|min:2|max:50'" @input="checkName()" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'client-form.client_name'
                              ),
                            }" />

                          <span v-show="errors.has('client-form.client_name')" class="error-text">{{
                            errors.first("client-form.client_name")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Client Logo *</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('client-form.client_logo'),
                            }">

                          <div class="field is-grouped">
                            <div class="control">
                              <div class="file has-name is-fullwidth">
                                <label class="file-label">
                                  <input class="file-input" type="file" @change="previewFiles" placeholder="">
                                  <span class="file-cta">
                                    <span class="file-icon">
                                      <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                    </span>
                                    <span class="file-label">
                                      Choose a file…
                                    </span>
                                  </span>
                                  <span class="file-name light-text">
                                    {{ this.client.client_logo ? this.client.client_logo.name : '' }}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <span v-show="!client.client_logo || client.client_logo == ''" class="error-text">Image is
                            required</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <img width="100" style="background-color: grey;"
                        v-if="client.client_logo && (typeof client.client_logo == 'object')" :src="this.tempObject">
                      <img width="100" style="background-color: grey;"
                        v-if="client.client_logo && (typeof client.client_logo == 'string')" :src="client.client_logo">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-card-foot is-centered">
              <div class="buttons">
                <button type="button" @click="show = 0;" class="button h-button is-light is-dark-outlined">
                  <span class="icon">
                    <i class="lnir lnir-arrow-left rem-100"></i>
                  </span>
                  <span>Cancel</span>
                </button>
                <button type="submit" id="save-button" class="button h-button is-primary is-raised"
                  :class="{ 'is-loading': buttonloader['client-form'] }">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template> 
<script>
import dataProcess from '../../util/dataProcess';
import draggable from "vuedraggable";
export default {
  name: "home",
  components: {
    draggable
  },
  data() {
    return {
      clients: [],
      bkClients: [],
      buttonloader: [],
      total: 0,
      importCsv: true,
      client: {
        client_name: "",
        client_logo: "",
        lang_id: this.$store.state.appLocale.langId,
        device: this.$store.state.appDevice.device
      },
      importData: [],
      type: "",
      index: "",
      show: 0,
      currentPage: 1,
      searchText: "",
      perPage: 10,
      tempObject: '',
      csv: [],
      file: "",
      csvValid: 0,
      error: [],
      csvError: 0,
      disable: 0
    };
  },
  created() {
    this.$store.state.isDetailLoading = false;
  },
  mounted() {
    this.getData();
  },

  beforeMount() { },

  methods: {
    updateOrder() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .postBulkData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/clients/update_data/display", this.clients)
        .then((response) => {
          if (response.status == 200) {
            this.$store.state.isDetailLoading = false;
          }
        });

    },
    getData() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .getData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/clients/list")
        .then((response) => {
          if (response.status == 200) {
            if (response.data) {
              this.bkClients = response.data.data;
              this.filterData();
            }
            this.$store.state.isDetailLoading = false;
          } else {
            this.total = 0;
          }
        });
    },
    updateStatusOrder(index, data) {
      if (
        confirm(
          "Are You Sure, want to " +
          (data.is_active == 1 ? "activate " : "inactivate") +
          " this client?"
        )
      ) {
        dataProcess
          .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/clients/update_data", data)
          .then((response) => {
            if (response.status == 200) {
              this.clients[index].is_active = data.is_active;
              if (data.is_active == 1) {
                this.$notyf.success("Activated successfully");
              } else {
                this.$notyf.error("Inactivated successfully");
              }

              this.$store.state.isDetailLoading = false;
            }
          });
      }
    },
    previewFiles(event) {
      this.client.client_logo = event.target.files[0];
      this.tempObject = URL.createObjectURL(this.client.client_logo);
    },
    deleteClient(index) {
      if (confirm("Are You Sure, Want To delete this client?")) {
        let data = this.clients[index];
        this.$store.state.isDetailLoading = true;
        dataProcess
          .deleteData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/clients/destory/" + data.client_id)
          .then((response) => {
            if (response.status == 200) {
              let index1 = this.bkClients.findIndex((el) => el.id == data.id);
              this.bkClients.splice(index1, 1);
              this.filterData();
              this.$notyf.success("Deleted successfully");
              this.$store.state.isDetailLoading = false;
            }
          });
      }
    },
    filterData() {
      let searchText = this.searchText.toLowerCase().trim();
      let filterdData;
      if (searchText != "") {
        filterdData = this.bkClients.filter(
          (el) =>
            (el.client_name && el.client_name.toLowerCase().indexOf(searchText) > -1)
        );
      } else {
        filterdData = this.bkClients;
      }


      this.total = filterdData.length;
      if (this.currentPage > 1) {
        let noOfPages = Math.ceil(this.total / parseInt(this.perPage));
        this.currentPage = noOfPages >= this.currentPage ? this.currentPage : noOfPages;
      }
      this.clients = filterdData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );

    },
    addEdit(type, index) {
      this.show = 1;
      this.type = type;
      if (type == "add") {
        this.client = {
          client_name: "",
          client_logo: "",
          lang_id: this.$store.state.appLocale.langId,
        device: this.$store.state.appDevice.device
        };
      } else {
        this.client = { ...this.clients[index] };
      }
    },
    onSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        this.$notyf.dismissAll();
        if (result && this.client.client_logo != '') {
          this.$store.state.isDetailLoading = true;
          this.buttonloader[scope] = true;
          if (this.type == "add") {
            this.client.createdby = this.$store.state.loginUserData.id;
            this.client.updatedby = this.$store.state.loginUserData.id;
            dataProcess
              .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/clients/store", this.client)
              .then((response) => {
                if (response.status == 200) {
                  if (response.data) {
                    this.client.id = response.data;
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.success("Client user added successfully");
                    this.getData();
                  } else {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error("Error in inserting data");
                  }
                }
                if (response.status == 422 || response.status == 500) {
                  this.buttonloader[scope] = false;
                  this.show = 0;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error(response.data);
                }
              });
          } else {
            let postData = { ...this.client };
            dataProcess
              .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/clients/update", postData)
              .then((response) => {
                if (response.status == 200) {
                  if (response.data) {
                    let index1 = this.bkClients.findIndex(
                      (el) => el.id == this.client.id
                    );
                    this.bkClients[index1] = response.data.data;
                    let index2 = this.clients.findIndex(
                      (el) => el.id == this.client.id
                    );
                    this.clients[index2] = response.data.data;
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.filterData();
                    this.$notyf.success("Client user updated successfully");
                  } else {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error("Error in inserting data");
                  }
                }
                if (response.status == 422 || response.status == 500) {
                  this.buttonloader[scope] = false;
                  this.show = 0;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error(response.data);
                }
              });
          }
        } else {
          this.buttonloader[scope] = false;
          this.$notyf.error("Correct form errors!!");
        }
      });
      this.filterData();
    },
    checkName() {
      console.log(this.client.client_name);
      if (this.client.client_name.trim() != "") {
        let index = this.bkClients.findIndex(it => it.client_name.trim().toLowerCase() == this.client.client_name.trim().toLowerCase());
        console.log(index);
        if (index > -1) {
          if (this.type == "add") {
            this.$notyf.error(
              "Entered Client name already exist in database!!"
            );
            this.client.client_name = "";
          } else {
            if (this.bkClients[index].id != this.client.id) {
              this.$notyf.error(
                "Entered Client name already exist in database!!"
              );
              this.client.client_name = "";
            }
          }
        }
      }
    },
    existAlready(el, i, resolve) {

      if (el.field1.trim() == '') {
        this.error.push('Row ' + (i + 1) + " -  Client name is empty, it is required field");
        resolve('');
      }
      else {
        if (this.bkClients.findIndex(it => it.client_name.trim().toLowerCase() == el.field1.trim().toLowerCase()) > -1) {
          this.error.push('Row ' + (i + 1) + " - " + el.field1 + ' Client name already exist in database!!');
        }
        else {
          this.importData.push("('" + el.field1 + "'," + 1 + ")");
        }
        resolve('');
      }

    },
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.getData();
    },
    '$store.state.appDevice': function (newVal, oldVal) {
      this.getData();
    }
  },
  metaInfo: {
    title: "Skiy31 :: Manage clients",
  },
};
</script>
<style>
.vue-csv-uploader-part-two {
  display: none;
}

.center-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
}

.file-input {
  height: auto;
}

#import-modal .column {
  padding: 0px;
}

.form-fieldset {
  padding: 20px 0;
  max-width: 480px;
  margin: 0 38px;
}
</style>
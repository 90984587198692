<template>
  <div id="app-onboarding" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-navbar-menu"
    data-mobile-item="#home-sidebar-menu-mobile" :class="{ 'is-pushed-full': $store.state.isSubmenuActive }">
    <div class="page-content-wrapper">
      <div class="page-content is-relative">
        <div class="page-title has-text-centered tabs-wrapper">
          <!-- Sidebar Trigger -->
          <sidebar />

          <div class="title-wrap">
            <h1 class="title is-4">Manage OurWorks </h1>
          </div>
          <toolbar />
        </div>

        <div class="datatable-toolbar">
          <div class="select">
            <select class="datatable-filter datatable-select form-control" data-filter="position"
              data-filter-type="default" v-model="perPage" @change="filterData">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          &nbsp;entries per page

          <div class="buttons">
            <div class="control mr-2">
              <input v-model="searchText" @keyup="filterData" class="input custom-text-filter" placeholder="Search..." />
              <div class="form-icon">
                <i data-feather="search"></i>
              </div>
            </div>
            <button class="button h-button is-primary is-elevated" @click="addEdit('add', '')">
              <span class="icon">
                <i aria-hidden="true" class="fas fa-plus"></i>
              </span>
              <span>Add</span>
            </button>
          </div>
        </div>

        <div class="page-content-inner">
          <!-- Datatable -->
          <div class="table-wrapper" data-simplebar>
            <table id="users-datatable" class="table is-datatable is-hoverable table-is-bordered">
              <thead>
                <tr>
                  <th>
                    <div class="control">#</div>
                  </th>
                  <th>Name</th>
                  <th>Client</th>
                  <th>Overview</th>
                  <th>Slug URL</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <draggable v-model="ourWorks" tag="tbody" @change="updateOrder">
                <tr data-id="0" v-for="(data, index) in this.ourWorks" :key="data.id">
                  <td>
                    <div class="control">
                      {{ parseInt(perPage) * (currentPage - 1) + index + 1 }}
                    </div>
                  </td>
                  <td class="wrap">
                    <img width="50" v-if="data.image && (typeof data.image == 'string')" :src="data.image" class="pl-2">
                    <span class="
                                                                                                    has-dark-text
                                                                                                    dark-inverted
                                                                                                    is-font-alt is-weight-600
                                                                                                    rem-90
                                                                                                  ">
                      {{ data.name }}
                    </span>
                  </td>
                  <td>{{ clients.findIndex(el => el.client_id == data.client_id) != -1
                    ? clients[clients.findIndex(el => el.client_id == data.client_id)].client_name : '' }}</td>
                  <td>{{ data.overview }}</td>
                  <td>{{ data.slug_url }}</td>
                  <td>
                    <div class="field is-grouped">
                      <div class="control">
                        <div class="thin-switch" :class="data.is_active == 0 ? 'is-danger' : 'is-success'">
                          <input :id="'thin-switch' + index" class="input" type="checkbox" :checked="data.is_active == 1"
                            @click="updateStatusOrder(index, { 'is_active': data.is_active == 0 ? 1 : 0, 'our_work_id': data.our_work_id })" />
                          <label :for="'thin-switch' + index" class="slider"></label> {{
                            data.is_active == 0 ? "Inactive" : "Active"
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="buttons">
                      <button class="
                                                                                                      button
                                                                                                      is-light is-circle
                                                                                                      hint--bubble hint--success hint--top
                                                                                                    " data-hint="Edit"
                        @click="addEdit('edit', index)">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>

                      <button @click="deleteOurWork(index)" class="
                                                                                                      button
                                                                                                      is-light is-circle
                                                                                                      hint--bubble hint--primary hint--top
                                                                                                    "
                        data-hint="Delete">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>
                      <button @click="addOurworkDetail(data.our_work_id)" class="
                                                                                    button
                                                                                    is-light is-circle
                                                                                    hint--bubble hint--primary hint--top
                                                                                  " data-hint="Add / Edit Detail">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
            <div class="section-placeholder" v-if="this.ourWorks.length == 0">
              <div class="placeholder-content">
                <img class="light-image" src="img/illustrations/placeholders/search-4.svg" alt="">
                <img class="dark-image" src="img/illustrations/placeholders/search-4-dark.svg" alt="">
                <h3 class="dark-inverted">No data to show</h3>
                <p>There is currently no data to show in this list.</p>
              </div>
            </div>
          </div>

          <div id="paging-first-datatable" v-if="this.ourWorks.length != 0" class="
                                                                                          pagination
                                                                                          datatable-pagination
                                                                                          pagination-datatables
                                                                                          text-center
                                                                                        ">
            <pagination v-model="currentPage" :per-page="parseInt(perPage)" :records="total" @paginate="filterData" />
          </div>
        </div>
      </div>
    </div>
    <div id="add-edit-modal" class="modal h-modal" :class="{ 'is-active': this.show != 0 }">
      <div class="modal-background h-modal-close"></div>
      <div class="modal-content">
        <form id="ourWork-form" data-vv-scope="ourWork-form" v-on:submit.prevent="onSubmit('ourWork-form')">
          <div class="modal-card">
            <header class="modal-card-head">
              <h3>
                {{
                  this.type == "add" ? "Add OurWork Details" : "Edit OurWork Details"
                }}
              </h3>
              <button class="h-modal-close ml-auto" aria-label="close" type="button">
                <i class="lnil lnil-close" @click="show = 0;"></i>
              </button>
            </header>
            <div class="modal-card-body">
              <div class="form-body">
                <!--Fieldset-->
                <div class="form-fieldset">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <div class="field">
                        <label>OurWork Name *</label>
                        <div class="control has-validation" :class="{
                          'has-error': errors.has('ourWork-form.name'),
                        }">
                          <input type="text" class="input" name="name" v-model="ourWork.name" placeholder=""
                            v-validate="'required|min:2|max:450'" @input="checkName()" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'ourWork-form.name'
                              ),
                            }" />

                          <span v-show="errors.has('ourWork-form.name')" class="error-text">{{
                            errors.first("ourWork-form.name")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Slug URL *</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('ourWork-form.slug_url'),
                            }">
                          <input type="text" class="input" name="slug_url" v-model="ourWork.slug_url" placeholder=""
                            v-validate="'required|min:2|max:450'" @input="checkURL()" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'ourWork-form.slug_url'
                              ),
                            }" />

                          <span v-show="errors.has('ourWork-form.slug_url')" class="error-text">{{
                            errors.first("ourWork-form.slug_url")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field has-validation" :class="{
                            'has-error': errors.has('ourWork-form.client_id'),
                          }">
                        <label>Client *</label>
                        <div class="control">
                          <div class="select">
                            <select class="form-control pr-10" style="font-size:1.074em" name="client_id"
                              v-model="ourWork.client_id" v-validate="''" :class="{
                                input: true,
                                'is-danger': errors.has(
                                  'ourWork-form.client_id'
                                ),
                              }">
                              <option value="">Select Client</option>
                              <option :value="data.client_id" v-for="data in clients" :key="data.id">{{ data.client_name
                              }}</option>
                            </select>
                          </div>


                        </div>
                        <span v-show="errors.has('ourWork-form.client_id')" class="error-text">{{
                          errors.first("ourWork-form.client_id")
                        }}</span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field has-validation" :class="{
                            'has-error': errors.has('ourWork-form.category_id'),
                          }">
                        <label>Category</label>
                        <div class="control">
                          <div class="select">
                            <multiselect :value="category_id" track-by="category_id" label="name" :searchable="true"
                              @input="selectedCategory" placeholder="Select Category" :options="categories"
                              :multiple="true">
                            </multiselect>
                          </div>


                        </div>
                        <span v-show="errors.has('ourWork-form.category_id')" class="error-text">{{
                          errors.first("ourWork-form.category_id")
                        }}</span>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field has-validation" :class="{
                            'has-error': errors.has('ourWork-form.tag_id'),
                          }">
                        <label>Tag *</label>
                        <div class="control">
                          <div class="select">
                            <multiselect :value="tag_id" track-by="tag_id" label="name" :searchable="true"
                              @input="selectedTag" placeholder="Select Tag" :options="tags" :multiple="true">
                            </multiselect>
                          </div>


                        </div>
                        <span v-show="errors.has('ourWork-form.tag_id')" class="error-text">{{
                          errors.first("ourWork-form.tag_id")
                        }}</span>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Overview</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('ourWork-form.overview'),
                            }">
                          <textarea class="textarea" name="overview" rows="5" cols="30" v-model="ourWork.overview"
                            placeholder="" v-validate="'max:450'" :class="{
                              'is-danger': errors.has(
                                'ourWork-form.overview'
                              ),
                            }" />


                          <span v-show="errors.has('ourWork-form.overview')" class="error-text">{{
                            errors.first("ourWork-form.overview")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>OurWork Image *</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('ourWork-form.image'),
                            }">

                          <div class="field is-grouped">
                            <div class="control">
                              <div class="file has-name is-fullwidth">
                                <label class="file-label">
                                  <input class="file-input" type="file" @change="previewFiles" placeholder="">
                                  <span class="file-cta">
                                    <span class="file-icon">
                                      <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                    </span>
                                    <span class="file-label">
                                      Choose a file…
                                    </span>
                                  </span>
                                  <span class="file-name light-text">
                                    {{ this.ourWork.image ? this.ourWork.image.name : '' }}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <span v-show="!ourWork.image || ourWork.image == ''" class="error-text">Image is
                            required</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <img width="100" v-if="ourWork.image && (typeof ourWork.image == 'object')" :src="tempObject">
                      <img width="100" v-if="ourWork.image && (typeof ourWork.image == 'string')" :src="ourWork.image">
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Meta Title *</label>
                        <div class="control has-validation" :class="{
                          'has-error': errors.has('ourWork-form.meta_title'),
                        }">
                          <input type="text" class="input" name="meta_title" v-model="ourWork.meta_title" placeholder=""
                            v-validate="'required|min:2|max:450'" @input="checkURL()" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'ourWork-form.meta_title'
                              ),
                            }" />

                          <span v-show="errors.has('ourWork-form.meta_title')" class="error-text">{{
                            errors.first("ourWork-form.meta_title")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Meta Keyword</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('ourWork-form.meta_keyword'),
                            }">
                          <input type="text" class="input" name="meta_keyword" v-model="ourWork.meta_keyword"
                            placeholder="" v-validate="'max:450'"  :class="{
                              input: true,
                              'is-danger': errors.has(
                                'ourWork-form.meta_keyword'
                              ),
                            }" />

                          <span v-show="errors.has('ourWork-form.meta_keyword')" class="error-text">{{
                            errors.first("ourWork-form.meta_keyword")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Meta description</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('ourWork-form.meta_description'),
                            }">
                          <textarea class="textarea" name="meta_description" rows="5" cols="30"
                            v-model="ourWork.meta_description" placeholder=""  :class="{
                              'is-danger': errors.has(
                                'ourWork-form.meta_description'
                              ),
                            }" />


                          <span v-show="errors.has('ourWork-form.meta_description')" class="error-text">{{
                            errors.first("ourWork-form.meta_description")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-card-foot is-centered">
              <div class="buttons">
                <button type="button" @click="show = 0;" class="button h-button is-light is-dark-outlined">
                  <span class="icon">
                    <i class="lnir lnir-arrow-left rem-100"></i>
                  </span>
                  <span>Cancel</span>
                </button>
                <button type="submit" id="save-button" class="button h-button is-primary is-raised"
                  :class="{ 'is-loading': buttonloader['ourWork-form'] }">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template> 
<script>
import dataProcess from '../../util/dataProcess';
import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";
export default {
  name: "home",
  components: {
    Multiselect,
    draggable
  },
  data() {
    return {
      ourWorks: [],
      clients: [],
      tags: [],
      category_id: [],
      tag_id: [],
      categories: [],
      bkOurWorks: [],
      buttonloader: [],
      total: 0,
      importCsv: true,
      ourWork: {
        name: "",
        image: "",
        client_id: "",
        category_id: [],
        tag_id: [],
        slug_url: "",
        meta_description: "",
        meta_title: "",
        overview: "",
        lang_id: this.$store.state.appLocale.langId,
        device: this.$store.state.appDevice.device
      },
      type: "",
      index: "",
      show: 0,
      currentPage: 1,
      searchText: "",
      perPage: 10,
      tempObject: ''
    };
  },
  created() {
    this.$store.state.isDetailLoading = false;
  },
  mounted() {
    this.getData();
    this.getMasterData();
  },

  beforeMount() { },

  methods: {
    updateOrder() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .postBulkData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/ourwork/update_data/display", this.ourWorks)
        .then((response) => {
          if (response.status == 200) {
            this.$store.state.isDetailLoading = false;
          }
        });

    },
    selectedCategory(val) {
      this.category_id = [];
      let categories = [];
      val.forEach((el) => {
        if (el.category_id) {
          this.category_id.push(el);
          categories.push(el.category_id);
        }
      })
      this.ourWork.category_id = categories.join(',');
    },
    selectedTag(val) {
      this.tag_id = [];
      let tags = [];
      val.forEach((el) => {
        if (el.tag_id) {
          this.tag_id.push(el);
          tags.push(el.tag_id);
        }
      })
      this.ourWork.tag_id = tags.join(',');
    },
    getData() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .getData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/ourwork/list")
        .then((response) => {
          if (response.status == 200) {
            if (response.data) {
              this.bkOurWorks = response.data.data;
              this.filterData();
            }
            this.$store.state.isDetailLoading = false;
          } else {
            this.total = 0;
          }
        });
    },
    addOurworkDetail(id) {
      this.$router.push({
        path: "/casestudy-details/" + id,
      });
    },
    getMasterData() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .getData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/clients/master-active-list")
        .then((response) => {
          if (response.status == 200) {
            if (response.data) {
              this.clients = response.data.data.clients;
              this.categories = response.data.data.category;
              this.tags = response.data.data.tags;
            }
            this.$store.state.isDetailLoading = false;
          } else {
            this.total = 0;
          }
        });
    },
    updateStatusOrder(index, data) {
      if (
        confirm(
          "Are You Sure, want to " +
          (data.is_active == 1 ? "activate " : "inactivate") +
          " this ourWork?"
        )
      ) {
        dataProcess
          .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/ourwork/update_data", data)
          .then((response) => {
            if (response.status == 200) {
              this.ourWorks[index].is_active = data.is_active;
              if (data.is_active == 1) {
                this.$notyf.success("Activated successfully");
              } else {
                this.$notyf.error("Inactivated successfully");
              }

              this.$store.state.isDetailLoading = false;
            }
          });
      }
    },
    previewFiles(event) {

      this.ourWork.image = event.target.files[0];
      this.tempObject = URL.createObjectURL(this.ourWork.image);
      console.log(this.ourWork.image);
    },
    deleteOurWork(index) {
      if (confirm("Are You Sure, Want To delete this ourWork?")) {
        let data = this.ourWorks[index];
        this.$store.state.isDetailLoading = true;
        dataProcess
          .deleteData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/ourwork/destory/" + data.ourWork_id)
          .then((response) => {
            if (response.status == 200) {
              let index1 = this.bkOurWorks.findIndex((el) => el.id == data.id);
              this.bkOurWorks.splice(index1, 1);
              this.filterData();
              this.$notyf.success("Deleted successfully");
              this.$store.state.isDetailLoading = false;
            }
          });
      }
    },
    filterData() {
      let searchText = this.searchText.toLowerCase().trim();
      let filterdData;
      if (searchText != "") {
        filterdData = this.bkOurWorks.filter(
          (el) =>
            (el.name && el.name.toLowerCase().indexOf(searchText) > -1)
        );
      } else {
        filterdData = this.bkOurWorks;
      }


      this.total = filterdData.length;
      if (this.currentPage > 1) {
        let noOfPages = Math.ceil(this.total / parseInt(this.perPage));
        this.currentPage = noOfPages >= this.currentPage ? this.currentPage : noOfPages;
      }
      this.ourWorks = filterdData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );

    },
    addEdit(type, index) {
      this.show = 1;
      this.type = type;
      this.tag_id = [];
      this.category_id = [];
      if (type == "add") {
        this.ourWork = {
          name: "",
          image: "",
          client_id: "",
          category_id: [],
          tag_id: [],
          slug_url: "",
          meta_description: "",
          meta_title: "",
          overview: "",
          lang_id: this.$store.state.appLocale.langId,
          device: this.$store.state.appDevice.device
        };
      } else {

        this.ourWork = { ...this.ourWorks[index] };
        let ids = this.ourWork.category_id.split(',');
        let tags = this.ourWork.tag_id.split(',');
        ids.forEach((el) => {
          let index = this.categories.findIndex(e => e.category_id == el);
          this.category_id.push(this.categories[index]);
        })
        tags.forEach((el) => {
          let index = this.tags.findIndex(e => e.tag_id == el);
          this.tag_id.push(this.tags[index]);
        })
        this.ourWork.meta_title = this.ourWork.meta_title ? this.ourWork.meta_title : '';
        this.ourWork.meta_description = this.ourWork.meta_description ? this.ourWork.meta_description : '';
        this.ourWork.meta_keyword = this.ourWork.meta_keyword ? this.ourWork.meta_keyword : '';
      }
    },
    onSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        this.$notyf.dismissAll();
        if (result && this.ourWork.image != '') {
          this.$store.state.isDetailLoading = true;
          this.buttonloader[scope] = true;
          if (this.type == "add") {
            dataProcess
              .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/ourwork/store", this.ourWork)
              .then((response) => {
                if (response.status == 200) {
                  if (response.data) {
                    this.ourWork.id = response.data;
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.success("OurWork added successfully");
                    this.getData();
                  } else {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error("Error in inserting data");
                  }
                }
                if (response.status == 422 || response.status == 500) {
                  this.buttonloader[scope] = false;
                  this.show = 0;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error(response.data);
                }
              });
          } else {
            let postData = { ...this.ourWork };
            dataProcess
              .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/ourwork/update", postData)
              .then((response) => {
                if (response.status == 200) {
                  if (response.data) {
                    let index1 = this.bkOurWorks.findIndex(
                      (el) => el.id == this.ourWork.id
                    );
                    this.bkOurWorks[index1] = response.data.data;
                    let index2 = this.ourWorks.findIndex(
                      (el) => el.id == this.ourWork.id
                    );
                    this.ourWorks[index2] = response.data.data;
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.filterData();
                    this.$notyf.success("OurWork updated successfully");
                  } else {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error("Error in inserting data");
                  }
                }
                if (response.status == 422 || response.status == 500) {
                  this.buttonloader[scope] = false;
                  this.show = 0;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error(response.data);
                }
              });
          }
        } else {
          this.buttonloader[scope] = false;
          this.$notyf.error("Correct form errors!!");
        }
      });
      this.filterData();
    },
    checkName() {
      console.log(this.ourWork.name);
      if (this.ourWork.name.trim() != "") {
        let index = this.bkOurWorks.findIndex(it => it.name.trim().toLowerCase() == this.ourWork.name.trim().toLowerCase());
        console.log(index);
        if (index > -1) {
          if (this.type == "add") {
            this.$notyf.error(
              "Entered OurWork name already exist in database!!"
            );
            this.ourWork.name = "";
          } else {
            if (this.bkOurWorks[index].id != this.ourWork.id) {
              this.$notyf.error(
                "Entered OurWork name already exist in database!!"
              );
              this.ourWork.name = "";
            }
          }
        }
      }
    },
    checkURL() {
      if (this.ourWork.slug_url.trim() != "") {
        let index = this.bkOurWorks.findIndex(it => it.slug_url.trim().toLowerCase() == this.ourWork.slug_url.trim().toLowerCase());
        if (index > -1) {
          if (this.type == "add") {
            this.$notyf.error(
              "Entered OurWork URL already exist in database!!"
            );
            this.ourWork.slug_url = "";
          } else {
            if (this.bkOurWorks[index].id != this.ourWork.id) {
              this.$notyf.error(
                "Entered OurWork URL already exist in database!!"
              );
              this.ourWork.slug_url = "";
            }
          }
        }
      }
    },
    existAlready(el, i, resolve) {

      if (el.field1.trim() == '') {
        this.error.push('Row ' + (i + 1) + " -  OurWork name is empty, it is required field");
        resolve('');
      }
      else {
        if (this.bkOurWorks.findIndex(it => it.name.trim().toLowerCase() == el.field1.trim().toLowerCase()) > -1) {
          this.error.push('Row ' + (i + 1) + " - " + el.field1 + ' OurWork name already exist in database!!');
        }
        else {
          this.importData.push("('" + el.field1 + "'," + 1 + ")");
        }
        resolve('');
      }

    },
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.getData();
    },
    '$store.state.appDevice': function (newVal, oldVal) {
      this.getData();
    }
  },
  metaInfo: {
    title: "Skiy31 :: Manage ourWorks",
  },
};
</script>
<style>
.vue-csv-uploader-part-two {
  display: none;
}

.center-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
}

.file-input {
  height: auto;
}

#import-modal .column {
  padding: 0px;
}

.form-fieldset {
  padding: 20px 0;
  max-width: 480px;
  margin: 0 38px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
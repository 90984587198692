import axios from 'axios';
import store from '../store'
import { Notyf } from 'notyf';
const notyf = new Notyf();
const dataProcess = {
  
  getData(url) {
    return axios.get(url, { headers: { 'Authorization': 'Bearer ' + store.state.token.access_token } }).catch(function (error) {
      if (error.response.status == 401) {
        store.dispatch('logout');
      }
      else {
        notyf.error(error.response.userMessage);
      }

    });
  },
  postData(url, data) {
    let headers = { 'Authorization': 'Bearer ' + store.state.token.access_token, 'Content-Type': 'multipart/form-data' }
    return axios.post(url, data, { headers: headers }).catch(function (error) {
      if (error.response.status == 401) {
        store.dispatch('logout');
      }
      else {
        notyf.error(error.response.userMessage);
      }
    });
  },
  postBulkData(url, data) {
    let headers = { 'Authorization': 'Bearer ' + store.state.token.access_token }
    return axios.post(url, data, { headers: headers }).catch(function (error) {
      if (error.response.status == 401) {
        store.dispatch('logout');
      }
      else {
        notyf.error(error.response.userMessage);
      }
    });
  },
  putData(url, data) {
    return axios.put(url, data, { headers: { 'Authorization': 'Bearer ' + store.state.token.access_token } });
  },
  deleteData(url) {
    return axios.delete(url, { headers: { 'Authorization': 'Bearer ' + store.state.token.access_token } });
  },
}
export default dataProcess;

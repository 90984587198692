<template>
  <div v-if="this.$store.state.isAuthenticated">
    <div class="app-overlay"></div>
    <!--Mobile navbar-->
    <nav class="navbar mobile-navbar no-shadow is-hidden-desktop is-hidden-tablet" aria-label="main navigation">
      <div class="container">
        <!-- Brand -->
        <div class="navbar-brand">
          <!-- Mobile menu toggler icon -->
          <div class="brand-start">
            <div class="navbar-burger" @click="mobileMenu()" :class="{ 'is-active': this.$store.state.mobileMenu }">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <RouterLink :to="{ name: 'ManageTestimonial' }" class="navbar-item is-brand">
            <img class="light-image" src="img/logos/logo/logo-small.png" alt="" />
            <img class="dark-image" src="img/logos/logo/logo-small-light.png" alt="" />
          </RouterLink>
        </div>
      </div>
    </nav>
    <!--Mobile sidebar-->
    <div class="mobile-main-sidebar" :class="{ 'is-active': this.$store.state.mobileMenu }">
      <div class="inner">
        <ul class="icon-side-menu">
          <li class="hint--primary hint--bubble hint--top-right" data-hint="Manage Unapproved users">
            <RouterLink :to="{ name: 'Home' }" :class="{ 'is-active': currentRouteName == 'Home' }" id="home-sidebar-menu"
              data-content="Waiting for approvals">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-home sidebar-svg">
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
            </RouterLink>
          </li>
          <!-- Layouts -->
          <li v-if="this.$store.state.loginUserData.is_super == 1" class="hint--primary hint--bubble hint--top-right"
            data-hint="Manage admin users">
            <RouterLink :to="{ name: 'Admins' }" :class="{ 'is-active': currentRouteName == 'Admins' }"
              id="layouts-sidebar-menu" data-content="Manage admin">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-users sidebar-svg">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </RouterLink>
          </li>
          <!-- Bounties -->
          <li class="hint--primary hint--bubble hint--top-right" data-hint="View Contact list">
            <RouterLink :to="{ name: 'ManageContact' }"
              :class="{ 'is-active': currentRouteName == 'ManageContact' }" id="elements-sidebar-menu"
              data-content="Elements">
              <i class="lnir lnir-user sidebar-svg"></i>
            </RouterLink>
          </li>

           <!-- Bounties -->
           <li class="hint--primary hint--bubble hint--top-right" data-hint="View Career list">
            <RouterLink :to="{ name: 'ManageCareer' }"
              :class="{ 'is-active': currentRouteName == 'ManageCareer' }" id="elements-sidebar-menu"
              data-content="Elements">
              <i class="lnir lnir-graduation sidebar-svg"></i>
            </RouterLink>
          </li>
          <!-- Bounties -->
          <li class="hint--primary hint--bubble hint--top-right" data-hint="View Subscribers list">
            <RouterLink :to="{ name: 'ManageSubscibe' }"
              :class="{ 'is-active': currentRouteName == 'ManageSubscibe' }" id="elements-sidebar-menu"
              data-content="Elements">
              <i class="lnir lnir-envelope sidebar-svg"></i>
            </RouterLink>
          </li>
          <!-- Bugs -->
          <li class="hint--primary hint--bubble hint--top-right" data-hint="Manage Case Study">
            <RouterLink :to="{ name: 'ManageOurWork' }" :class="{ 'is-active': currentRouteName == 'ManageOurWork' }"
              id="components-sidebar-menu" data-content="Components">
              <i class="lnir lnir-identity sidebar-svg"></i>
            </RouterLink>
          </li>
          <!-- Bugs -->
          <li class="hint--primary hint--bubble hint--top-right" data-hint="Manage Blog">
            <RouterLink :to="{ name: 'ManageBlog' }" :class="{ 'is-active': currentRouteName == 'ManageBlog' }"
              id="components-sidebar-menu" data-content="Components">
              <i class="lnir lnir lnir-medical-briefcase-alt sidebar-svg"></i>
            </RouterLink>
          </li>
          <li class="hint--primary hint--bubble hint--top-right" data-hint="Manage Master">
            <RouterLink :to="{ name: 'ManageClient' }" :class="{
              'is-active':
                currentRouteName == 'ManageClient' ||
                currentRouteName == 'ManageCategory' ||
                currentRouteName == 'ManageTag',
            }" id="components-sidebar-menu" data-content="Components">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-layers sidebar-svg">
                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                <polyline points="2 17 12 22 22 17"></polyline>
                <polyline points="2 12 12 17 22 12"></polyline>
              </svg>
            </RouterLink>
          </li>
        </ul>
        <ul class="bottom-icon-side-menu">
          <!-- Profile -->
          <li id="user-menu">
            <div id="profile-menu" class="dropdown profile-dropdown dropdown-trigger is-spaced is-up"
              :class="{ 'is-active': $store.state.dropdown }">
              <img src="img/avatars/photos/8.jpg" data-demo-src="img/avatars/photos/8.jpg" alt=""
                @click="$store.state.dropdown = !$store.state.dropdown" />
              <span class="status-indicator"></span>

              <div class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <div class="dropdown-head">
                    <div class="h-avatar is-large">
                      <img class="avatar" src="img/avatars/photos/8.jpg" data-demo-src="img/avatars/photos/8.jpg"
                        alt="" />
                    </div>
                    <div class="meta">
                      <!-- <span>{{
                        userData.name +
                        " " +
                        userData.surname
                      }}</span> -->
                    </div>
                  </div>
                  <RouterLink :to="{ name: 'UpdateProfile' }" class="dropdown-item is-media">
                    <div class="icon">
                      <i class="lnil lnil-user-alt"></i>
                    </div>
                    <div class="meta">
                      <span>Profile</span>
                      <span>View your profile</span>
                    </div>
                  </RouterLink>
                  <hr class="dropdown-divider" />
                  <RouterLink v-if="this.$store.state.loginUserData.is_super == 1" :to="{ name: 'Admins' }"
                    class="dropdown-item is-media">
                    <div class="icon">
                      <i class="lnil lnil-cog"></i>
                    </div>
                    <div class="meta">
                      <span>Settings</span>
                      <span>Account settings</span>
                    </div>
                  </RouterLink>
                  <hr class="dropdown-divider" />
                  <div class="dropdown-item is-button">
                    <button @click="logout()" class="
                                  button
                                  h-button
                                  is-primary is-raised is-fullwidth
                                  logout-button
                                ">
                      <span class="icon is-small">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-log-out">
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                          <polyline points="16 17 21 12 16 7"></polyline>
                          <line x1="21" y1="12" x2="9" y2="12"></line>
                        </svg>
                      </span>
                      <span>Logout</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--Sidebar-->
    <div class="main-sidebar">
      <div class="sidebar-brand">
        <RouterLink :to="{ name: 'ManageTestimonial' }">
          <img class="light-image" src="img/logos/logo/logo-small.png" alt="" />
          <img class="dark-image" src="img/logos/logo/logo-small-light.png" alt="" />
        </RouterLink>
      </div>
      <div class="sidebar-inner">
        <ul class="icon-menu">
          <!-- Layouts -->
          <li v-if="this.$store.state.loginUserData.is_super == 1" class="hint--primary hint--bubble hint--top-right"
            data-hint="Manage admin users">
            <RouterLink :to="{ name: 'Admins' }" :class="{ 'is-active': currentRouteName == 'Admins' }"
              id="layouts-sidebar-menu" data-content="Manage admin">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-users sidebar-svg">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </RouterLink>
          </li>
          <!-- Bounties -->
          <li class="hint--primary hint--bubble hint--top-right" data-hint="Manage Testimonials">
            <RouterLink :to="{ name: 'ManageTestimonial' }"
              :class="{ 'is-active': currentRouteName == 'ManageTestimonial' }" id="elements-sidebar-menu"
              data-content="Elements">
              <i class="lnir lnir-medical-protection sidebar-svg"></i>
            </RouterLink>
          </li>
          <li class="hint--primary hint--bubble hint--top-right" data-hint="View Contact list">
            <RouterLink :to="{ name: 'ManageContact' }"
              :class="{ 'is-active': currentRouteName == 'ManageContact' }" id="elements-sidebar-menu"
              data-content="Elements">
              <i class="lnir lnir-user sidebar-svg"></i>
            </RouterLink>
          </li>
          <li class="hint--primary hint--bubble hint--top-right" data-hint="View Career list">
            <RouterLink :to="{ name: 'ManageCareer' }"
              :class="{ 'is-active': currentRouteName == 'ManageCareer' }" id="elements-sidebar-menu"
              data-content="Elements">
              <i class="lnir lnir-graduation sidebar-svg"></i>
            </RouterLink>
          </li>
          <li class="hint--primary hint--bubble hint--top-right" data-hint="View Subscribers list">
            <RouterLink :to="{ name: 'ManageSubscribe' }"
              :class="{ 'is-active': currentRouteName == 'ManageSubscribe' }" id="elements-sidebar-menu"
              data-content="Elements">
              <i class="lnir lnir-envelope sidebar-svg"></i>
            </RouterLink>
          </li>
          <!-- Bugs -->
          <li class="hint--primary hint--bubble hint--top-right" data-hint="Manage Case Study">
            <RouterLink :to="{ name: 'ManageOurWork' }" :class="{ 'is-active': currentRouteName == 'ManageOurWork' }"
              id="components-sidebar-menu" data-content="Components">
              <i class="lnir lnir-identity sidebar-svg"></i>
            </RouterLink>
          </li>
          <!-- Bugs -->
          <li class="hint--primary hint--bubble hint--top-right" data-hint="Manage Blog">
            <RouterLink :to="{ name: 'ManageBlog' }" :class="{ 'is-active': currentRouteName == 'ManageBlog' }"
              id="components-sidebar-menu" data-content="Components">
              <i class="lnir lnir lnir-medical-briefcase-alt sidebar-svg"></i>
            </RouterLink>
          </li>
          <li class="hint--primary hint--bubble hint--top-right" data-hint="Manage Master">
            <RouterLink :to="{ name: 'ManageClient' }" :class="{
              'is-active':
                currentRouteName == 'ManageClient' ||
                currentRouteName == 'ManageCategory' ||
                currentRouteName == 'ManageTag',
            }" id="components-sidebar-menu" data-content="Components">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-layers sidebar-svg">
                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                <polyline points="2 17 12 22 22 17"></polyline>
                <polyline points="2 12 12 17 22 12"></polyline>
              </svg>
            </RouterLink>
          </li>
        </ul>

        <!-- User account -->
        <ul class="bottom-menu">
          <!-- Profile -->
          <li id="user-menu">
            <div id="profile-menu" class="dropdown profile-dropdown dropdown-trigger is-spaced is-up"
              :class="{ 'is-active': $store.state.dropdown }">
              <img src="img/avatars/photos/8.jpg" data-demo-src="img/avatars/photos/8.jpg" alt=""
                @click="$store.state.dropdown = !$store.state.dropdown" />
              <span class="status-indicator"></span>

              <div class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <div class="dropdown-head">
                    <div class="h-avatar is-large">
                      <img class="avatar" src="img/avatars/photos/8.jpg" data-demo-src="img/avatars/photos/8.jpg"
                        alt="" />
                    </div>
                    <div class="meta">
                      <!-- <span>{{
                        userData.name +
                        " " +
                        userData.surname
                      }}</span> -->
                    </div>
                  </div>
                  <RouterLink :to="{ name: 'UpdateProfile' }" class="dropdown-item is-media">
                    <div class="icon">
                      <i class="lnil lnil-user-alt"></i>
                    </div>
                    <div class="meta">
                      <span>Profile</span>
                      <span>View your profile</span>
                    </div>
                  </RouterLink>
                  <hr class="dropdown-divider" />
                  <RouterLink v-if="this.$store.state.loginUserData.is_super == 1" :to="{ name: 'Admins' }"
                    class="dropdown-item is-media">
                    <div class="icon">
                      <i class="lnil lnil-cog"></i>
                    </div>
                    <div class="meta">
                      <span>Settings</span>
                      <span>Account settings</span>
                    </div>
                  </RouterLink>
                  <hr class="dropdown-divider" />
                  <div class="dropdown-item is-button">
                    <button @click="logout()" class="
                                  button
                                  h-button
                                  is-primary is-raised is-fullwidth
                                  logout-button
                                ">
                      <span class="icon is-small">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-log-out">
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                          <polyline points="16 17 21 12 16 7"></polyline>
                          <line x1="21" y1="12" x2="9" y2="12"></line>
                        </svg>
                      </span>
                      <span>Logout</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobile-subsidebar" :class="{
      'is-active':
        this.$store.state.mobileMenu && this.$store.state.isSubmenuActive,
    }">
      <div class="inner">
        <div class="sidebar-title">
          <h3>Masters</h3>
        </div>

        <ul class="submenu">
          <li :class="{ 'is-active': currentRouteName == 'ManageClient' }">
            <RouterLink :to="{ name: 'ManageClient' }">Clients</RouterLink>
          </li>
          <li :class="{ 'is-active': currentRouteName == 'ManageCategory' }">
            <RouterLink :to="{ name: 'ManageCategory' }">Services</RouterLink>
          </li>
          <li :class="{ 'is-active': currentRouteName == 'ManageTag' }">
            <RouterLink :to="{ name: 'ManageTag' }">Tags</RouterLink>
          </li>
        </ul>
      </div>
    </div>
    <div id="elements-sidebar" class="sidebar-panel is-generic" :class="{
      'is-active':
        (currentRouteName == 'ManageClient' ||
          currentRouteName == 'ManageCategory' ||
          currentRouteName == 'ManageTag') &&
        $store.state.isSubmenuActive == true
    }">
      <div class="subpanel-header">
        <h3 class="no-mb">Masters</h3>
        <div class="panel-close">
          <i data-feather="x"></i>
        </div>
      </div>
      <div class="inner" data-simplebar>
        <ul>
          <li :class="{ 'is-active': currentRouteName == 'ManageClient' }">
            <RouterLink :to="{ name: 'ManageClient' }">Clients</RouterLink>
          </li>
          <li :class="{ 'is-active': currentRouteName == 'ManageCategory' }">
            <RouterLink :to="{ name: 'ManageCategory' }">Services</RouterLink>
          </li>
          <li :class="{ 'is-active': currentRouteName == 'ManageTag' }">
            <RouterLink :to="{ name: 'ManageTag' }">Tags</RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import dataProcess from '../util/dataProcess';
export default {
  name: "Navbar",

  data() {
    return {
      dropdown: false,
      userData: []
    };
  },
  created() {
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.$store.state.dropdown = false;
      }
    });
  },
  beforeCreate() {
    if (this.$store.state.isAuthenticated) {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .getData(this.$path + "api/myprofile")
        .then((response) => {
          if (response.status == 200) {
            if (response.data) {
              this.userData = response.data.data;
              this.$store.commit("updateLoginUser", this.userData);
            }
            this.$store.state.isDetailLoading = false;
          } else {
            this.total = 0;
          }
        });
    }
  },

  beforeMount() {
    if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
      localStorage.removeItem("currentUser");
      this.$store.commit("isLogin", false);
    }
  },

  methods: {
    mobileMenu() {
      this.$store.state.mobileMenu = !this.$store.state.mobileMenu;
    },
    logout() {
      this.$store.dispatch('logout');
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    '$store.state.isAuthenticated': function () {
      if (!this.$store.state.isAuthenticated) {
        this.$router.push({
          path: "/login",
        });
      }
    }
  }
};
</script>

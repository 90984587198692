<template>
  <div id="app-onboarding" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-navbar-menu"
    data-mobile-item="#home-sidebar-menu-mobile" :class="{ 'is-pushed-full': $store.state.isSubmenuActive }">
    <div class="page-content-wrapper">
      <div class="page-content is-relative">
        <div class="page-title has-text-centered tabs-wrapper">
          <!-- Sidebar Trigger -->
          <sidebar />

          <div class="title-wrap">
            <h1 class="title is-4">Manage Blogs </h1>
          </div>
          <toolbar />
        </div>

        <div class="datatable-toolbar">
          <div class="select">
            <select class="datatable-filter datatable-select form-control" data-filter="position"
              data-filter-type="default" v-model="perPage" @change="filterData">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          &nbsp;entries per page

          <div class="buttons">
            <div class="control mr-2">
              <input v-model="searchText" @keyup="filterData" class="input custom-text-filter" placeholder="Search..." />
              <div class="form-icon">
                <i data-feather="search"></i>
              </div>
            </div>
            <button class="button h-button is-primary is-elevated" @click="addEdit('add', '')">
              <span class="icon">
                <i aria-hidden="true" class="fas fa-plus"></i>
              </span>
              <span>Add</span>
            </button>
          </div>
        </div>

        <div class="page-content-inner">
          <!-- Datatable -->
          <div class="table-wrapper" data-simplebar>
            <table id="users-datatable" class="table is-datatable is-hoverable table-is-bordered">
              <thead>
                <tr>
                  <th>
                    <div class="control">#</div>
                  </th>
                  <th>Title</th>
                  <th>Read Time</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <draggable v-model="blogs" tag="tbody" @change="updateOrder">
                <tr data-id="0" v-for="(data, index) in this.blogs" :key="data.id">
                  <td>
                    <div class="control">
                      {{ parseInt(perPage) * (currentPage - 1) + index + 1 }}
                    </div>
                  </td>
                  <td class="wrap">
                    <span class="
                                                                                                  has-dark-text
                                                                                                  dark-inverted
                                                                                                  is-font-alt is-weight-600
                                                                                                  rem-90
                                                                                                ">
                      {{ data.title }}
                    </span>
                  </td>
                  <td class="wrap">
                    {{ data.reading_time }}
                  </td>
                  <td class="wrap">
                    {{ data.date }}
                  </td>
                  <td>
                    <div class="field is-grouped">
                      <div class="control">
                        <div class="thin-switch" :class="data.is_active == 0 ? 'is-danger' : 'is-success'">
                          <input :id="'thin-switch' + index" class="input" type="checkbox" :checked="data.is_active == 1"
                            @click="updateStatusOrder(index, { 'is_active': data.is_active == 0 ? 1 : 0, 'blog_id': data.blog_id })" />
                          <label :for="'thin-switch' + index" class="slider"></label> {{
                            data.is_active == 0 ? "Inactive" : "Active"
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="buttons">
                      <button class="
                                                                                                    button
                                                                                                    is-light is-circle
                                                                                                    hint--bubble hint--success hint--top
                                                                                                  " data-hint="Edit"
                        @click="addEdit('edit', index)">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>

                      <button @click="deleteBlog(index)" class="
                                                                                                    button
                                                                                                    is-light is-circle
                                                                                                    hint--bubble hint--primary hint--top
                                                                                                  " data-hint="Delete">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>
                      <button @click="addBlogDetail(data.blog_id)" class="
                                                                                                    button
                                                                                                    is-light is-circle
                                                                                                    hint--bubble hint--primary hint--top
                                                                                                  "
                        data-hint="Add / Edit Detail">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
            <div class="section-placeholder" v-if="this.blogs.length == 0">
              <div class="placeholder-content">
                <img class="light-image" src="img/illustrations/placeholders/search-4.svg" alt="">
                <img class="dark-image" src="img/illustrations/placeholders/search-4-dark.svg" alt="">
                <h3 class="dark-inverted">No data to show</h3>
                <p>There is currently no data to show in this list.</p>
              </div>
            </div>
          </div>

          <div id="paging-first-datatable" v-if="this.blogs.length != 0" class="
                                                                                        pagination
                                                                                        datatable-pagination
                                                                                        pagination-datatables
                                                                                        text-center
                                                                                      ">
            <pagination v-model="currentPage" :per-page="parseInt(perPage)" :records="total" @paginate="filterData" />
          </div>
        </div>
      </div>
    </div>
    <div id="add-edit-modal" class="modal h-modal" :class="{ 'is-active': this.show != 0 }">
      <div class="modal-background h-modal-close"></div>
      <div class="modal-content">
        <form id="blog-form" data-vv-scope="blog-form" v-on:submit.prevent="onSubmit('blog-form')">
          <div class="modal-card">
            <header class="modal-card-head">
              <h3>
                {{
                  this.type == "add" ? "Add Blog Details" : "Edit Blog Details"
                }}
              </h3>
              <button class="h-modal-close ml-auto" aria-label="close" type="button">
                <i class="lnil lnil-close" @click="show = 0;"></i>
              </button>
            </header>
            <div class="modal-card-body">
              <div class="form-body">
                <!--Fieldset-->
                <div class="form-fieldset">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <div class="field">
                        <label>Blog Title *</label>
                        <div class="control has-validation" :class="{
                          'has-error': errors.has('blog-form.title'),
                        }">
                          <input type="text" class="input" name="title" v-model="blog.title" placeholder=""
                            v-validate="'required|min:2|max:100'" @input="checkName()" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'blog-form.title'
                              ),
                            }" />

                          <span v-show="errors.has('blog-form.title')" class="error-text">{{
                            errors.first("blog-form.title")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Blog Read Time *</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('blog-form.reading_time'),
                            }">
                          <input type="text" class="input" name="reading_time" v-model="blog.reading_time" placeholder=""
                            v-validate="'required|min:2|max:150'" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'blog-form.reading_time'
                              ),
                            }" />

                          <span v-show="errors.has('blog-form.reading_time')" class="error-text">{{
                            errors.first("blog-form.reading_time")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Blog Date *</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('blog-form.date'),
                            }">
                          <date-picker name="date" data-vv-as="date" v-model="blog.date" format="YYYY-MM-DD"
                            valueType="format" type="date" placeholder="Select date" v-validate="'required'" :class="{
                              'is-danger': errors.has('patient-form.dob'),
                            }">
                          </date-picker>

                          <span v-show="errors.has('blog-form.date')" class="error-text">{{
                            errors.first("blog-form.date")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Blog External Url *</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('blog-form.url'),
                            }">
                          <input type="text" class="input" name="url" v-model="blog.url" placeholder=""
                            v-validate="'required'" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'blog-form.url'
                              ),
                            }" />

                          <span v-show="errors.has('blog-form.url')" class="error-text">{{
                            errors.first("blog-form.url")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Blog Description *</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('blog-form.description'),
                            }">
                          <trumbowyg v-model="blog.description" :config="editorConfig"></trumbowyg>
                          <span v-show="errors.has('blog-form.description')" class="error-text">{{
                            errors.first("blog-form.description")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Blog Image *</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('blog-form.blog_image'),
                            }">

                          <div class="field is-grouped">
                            <div class="control">
                              <div class="file has-name is-fullwidth">
                                <label class="file-label">
                                  <input class="file-input" type="file" @change="previewFiles" placeholder="">
                                  <span class="file-cta">
                                    <span class="file-icon">
                                      <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                    </span>
                                    <span class="file-label">
                                      Choose a file…
                                    </span>
                                  </span>
                                  <span class="file-name light-text">
                                    {{ this.blog.blog_image ? this.blog.blog_image.name : '' }}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <span v-show="!blog.blog_image || blog.blog_image == ''" class="error-text">Image is
                            required</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <img width="100" style="background-color: grey;"
                        v-if="blog.blog_image && (typeof blog.blog_image == 'object')" :src="this.tempObject">
                      <img width="100" style="background-color: grey;"
                        v-if="blog.blog_image && (typeof blog.blog_image == 'string')" :src="blog.blog_image">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-card-foot is-centered">
              <div class="buttons">
                <button type="button" @click="show = 0;" class="button h-button is-light is-dark-outlined">
                  <span class="icon">
                    <i class="lnir lnir-arrow-left rem-100"></i>
                  </span>
                  <span>Cancel</span>
                </button>
                <button type="submit" id="save-button" class="button h-button is-primary is-raised"
                  :class="{ 'is-loading': buttonloader['blog-form'] }">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</template> 
<script>
import dataProcess from '../../util/dataProcess';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import draggable from "vuedraggable";
export default {
  components: {
    DatePicker,
    draggable
  },
  name: 'blog',
  data() {
    return {

      editorConfig: {
        lang: this.$store.state.appLocale.langId,
        autogrow: true,
        removeformatPasted: true,
        tagsToKeep: ['div', 'img'],
        btns: [
          ['bold', 'italic'], ['formatting'], ['link'], ['viewHTML'], ['fullscreen']
        ]
      },
      blogs: [],
      bkBlogs: [],
      buttonloader: [],
      total: 0,
      tempObject: '',
      blog: {
        title: "",
        url: "",
        date: "",
        reading_time: "",
        blog_image: "",
        description: "",
        lang_id: this.$store.state.appLocale.langId,
        device: this.$store.state.appDevice.device
      },
      type: "",
      index: "",
      show: 0,
      currentPage: 1,
      searchText: "",
      perPage: 10
    };
  },
  created() {
    this.$store.state.isDetailLoading = false;
  },
  mounted() {
    this.getData();
  },

  beforeMount() { },

  methods: {
    updateOrder() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .postBulkData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/blogs/update_data/display", this.blogs)
        .then((response) => {
          if (response.status == 200) {
            this.$store.state.isDetailLoading = false;
          }
        });

    },
    getData() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .getData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/blogs/list")
        .then((response) => {
          if (response.status == 200) {
            if (response.data) {
              this.bkBlogs = response.data.data;
              this.filterData();
            }
            this.$store.state.isDetailLoading = false;
          } else {
            this.total = 0;
          }
        });
    },
    updateStatusOrder(index, data) {
      if (
        confirm(
          "Are You Sure, want to " +
          (data.is_active == 1 ? "activate " : "inactivate") +
          " this blog?"
        )
      ) {
        dataProcess
          .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/blogs/update_data", data)
          .then((response) => {
            if (response.status == 200) {
              this.blogs[index].is_active = data.is_active;
              if (data.is_active == 1) {
                this.$notyf.success("Activated successfully");
              } else {
                this.$notyf.error("Inactivated successfully");
              }

              this.$store.state.isDetailLoading = false;
            }
          });
      }
    },
    deleteBlog(index) {
      if (confirm("Are You Sure, Want To delete this blog?")) {
        let data = this.blogs[index];
        this.$store.state.isDetailLoading = true;
        dataProcess
          .deleteData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/blogs/destory/" + data.blog_id)
          .then((response) => {
            if (response.status == 200) {
              let index1 = this.bkBlogs.findIndex((el) => el.id == data.id);
              this.bkBlogs.splice(index1, 1);
              this.filterData();
              this.$notyf.success("Deleted successfully");
              this.$store.state.isDetailLoading = false;
            }
          });
      }
    },
    previewFiles(event) {
      this.blog.blog_image = event.target.files[0];
      this.tempObject = URL.createObjectURL(this.blog.blog_image);
    },
    filterData() {
      let searchText = this.searchText.toLowerCase().trim();
      let filterdData;
      if (searchText != "") {
        filterdData = this.bkBlogs.filter(
          (el) =>
            (el.title && el.title.toLowerCase().indexOf(searchText) > -1)
        );
      } else {
        filterdData = this.bkBlogs;
      }


      this.total = filterdData.length;
      if (this.currentPage > 1) {
        let noOfPages = Math.ceil(this.total / parseInt(this.perPage));
        this.currentPage = noOfPages >= this.currentPage ? this.currentPage : noOfPages;
      }
      this.blogs = filterdData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );

    },
    addBlogDetail(id) {
      this.$router.push({
        path: "/blog-details/" + id,
      });
    },
    addEdit(type, index) {
      this.show = 1;
      this.type = type;
      if (type == "add") {
        this.blog = {
          title: "",
          url: "",
          date: "",
          reading_time: "",
          blog_image: "",
          description: "",
          lang_id: this.$store.state.appLocale.langId,
          device: this.$store.state.appDevice.device
        };
      } else {
        this.blog = { ...this.blogs[index] };
        this.blog.url = this.blog.url ? this.blog.url : '';
      }
    },
    onSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        this.$notyf.dismissAll();
        if (result && this.blog.blog_logo != '') {
          this.$store.state.isDetailLoading = true;
          this.buttonloader[scope] = true;
          if (this.type == "add") {
            this.blog.createdby = this.$store.state.loginUserData.id;
            this.blog.updatedby = this.$store.state.loginUserData.id;
            dataProcess
              .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/blogs/store", this.blog)
              .then((response) => {
                if (response.status == 200) {
                  if (response.data) {
                    this.blog.id = response.data;
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.success("Blog user added successfully");
                    this.getData();
                  } else {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error("Error in inserting data");
                  }
                }
                if (response.status == 422 || response.status == 500) {
                  this.buttonloader[scope] = false;
                  this.show = 0;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error(response.data);
                }
              });
          } else {
            let postData = { ...this.blog };
            dataProcess
              .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/blogs/update", postData)
              .then((response) => {
                if (response.status == 200) {
                  if (response.data) {
                    let index1 = this.bkBlogs.findIndex(
                      (el) => el.id == this.blog.id
                    );
                    this.bkBlogs[index1] = response.data.data;
                    let index2 = this.blogs.findIndex(
                      (el) => el.id == this.blog.id
                    );
                    this.blogs[index2] = response.data.data;
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.filterData();
                    this.$notyf.success("Blog user updated successfully");
                  } else {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error("Error in inserting data");
                  }
                }
                if (response.status == 422 || response.status == 500) {
                  this.buttonloader[scope] = false;
                  this.show = 0;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error(response.data);
                }
              });
          }
        } else {
          this.buttonloader[scope] = false;
          this.$notyf.error("Correct form errors!!");
        }
      });
      this.filterData();
    },
    checkName() {
      console.log(this.blog.title);
      if (this.blog.title.trim() != "") {
        let index = this.bkBlogs.findIndex(it => it.title.trim().toLowerCase() == this.blog.title.trim().toLowerCase());
        console.log(index);
        if (index > -1) {
          if (this.type == "add") {
            this.$notyf.error(
              "Entered Blog title already exist in database!!"
            );
            this.blog.title = "";
          } else {
            if (this.bkBlogs[index].id != this.blog.id) {
              this.$notyf.error(
                "Entered Blog title already exist in database!!"
              );
              this.blog.title = "";
            }
          }
        }
      }
    },
    existAlready(el, i, resolve) {

      if (el.field1.trim() == '') {
        this.error.push('Row ' + (i + 1) + " -  Blog title is empty, it is required field");
        resolve('');
      }
      else {
        if (this.bkBlogs.findIndex(it => it.title.trim().toLowerCase() == el.field1.trim().toLowerCase()) > -1) {
          this.error.push('Row ' + (i + 1) + " - " + el.field1 + ' Blog title already exist in database!!');
        }
        else {
          this.importData.push("('" + el.field1 + "'," + 1 + ")");
        }
        resolve('');
      }

    },
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.getData();
    },
    '$store.state.appDevice.device': function (newVal, oldVal) {
      this.getData();
    }
  },
  metaInfo: {
    title: "Skiy31 :: Manage blogs",
  },
};
</script>
<style>
.vue-csv-uploader-part-two {
  display: none;
}

.center-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
}

.file-input {
  height: auto;
}

.file-name {
  word-break: break-all;
  max-width: 52% !important;
}

#import-modal .column {
  padding: 0px;
}

.form-fieldset {
  padding: 20px 0;
  max-width: 480px;
  margin: 0 38px;
}
</style>
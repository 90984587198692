var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper"},[_c('div',{staticClass:"modern-login"},[_c('div',{staticClass:"underlay h-hidden-mobile h-hidden-tablet-p"}),_c('div',{staticClass:"columns is-gapless is-vcentered"},[_vm._m(0),_c('div',{staticClass:"column is-4 is-relative"},[_vm._m(1),_c('div',{staticClass:"is-form"},[_c('div',{staticClass:"hero-body"},[_vm._m(2),_c('form',{staticClass:"login-wrapper",attrs:{"id":"password-form","autocomplete":"off","data-vv-scope":"password-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit('password-form')}}},[_c('div',{staticClass:"control has-validation",class:{ 'has-error': _vm.errors.has('password-form.password') }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"},{name:"validate",rawName:"v-validate",value:('required|min:8|max:20|verify_password'),expression:"'required|min:8|max:20|verify_password'"}],ref:"password",staticClass:"input",class:{
                    input: true,
                    'is-danger': _vm.errors.has('password-form.password'),
                  },attrs:{"type":"password","autocomplete":"off","name":"password","placeholder":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password-form.password')),expression:"errors.has('password-form.password')"}],staticClass:"error-text"},[_vm._v(_vm._s(_vm.errors.first("password-form.password")))]),_c('div',{staticClass:"auth-label"},[_vm._v("Password *")]),_vm._m(3)]),_c('div',{staticClass:"control has-validation",class:{
                'has-error': _vm.errors.has('password-form.confirm_password'),
              }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordMatch),expression:"passwordMatch"},{name:"validate",rawName:"v-validate",value:('required|confirmed:password'),expression:"'required|confirmed:password'"}],staticClass:"input",class:{
                    input: true,
                    'is-danger': _vm.errors.has('password-form.confirm_password'),
                  },attrs:{"type":"password","autocomplete":"off","name":"confirm_password"},domProps:{"value":(_vm.passwordMatch)},on:{"input":function($event){if($event.target.composing){ return; }_vm.passwordMatch=$event.target.value}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password-form.confirm_password')),expression:"errors.has('password-form.confirm_password')"}],staticClass:"error-text"},[_vm._v(_vm._s(_vm.errors.first("password-form.confirm_password")))]),_c('div',{staticClass:"auth-label"},[_vm._v("Confirm Password *")]),_vm._m(4)]),_c('div',{staticClass:"button-wrap has-help"},[_c('button',{staticClass:"\n                    button\n                    h-button\n                    is-big is-rounded is-primary is-bold is-raised\n                  ",class:{ 'is-loading': _vm.buttonloader },attrs:{"id":"login-submit","type":"submit"}},[_vm._v(" Submit ")])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-relative is-8 h-hidden-mobile h-hidden-tablet-p"},[_c('div',{staticClass:"hero is-fullheight is-image"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('img',{staticClass:"hero-image",attrs:{"src":"img/illustrations/login/station.svg","alt":""}})])])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"top-logo",attrs:{"href":"index.html"}},[_c('img',{staticClass:"light-image",attrs:{"src":"img/logos/logo/logo.png","alt":""}}),_c('img',{staticClass:"dark-image",attrs:{"src":"img/logos/logo/logo-light.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-text"},[_c('h2',[_vm._v("Reset Password")]),_c('p',[_vm._v("Password must strong one.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-icon"},[_c('i',{staticClass:"lnil lnil-envelope"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-icon"},[_c('i',{staticClass:"lnil lnil-lock-alt"})])}]

export { render, staticRenderFns }
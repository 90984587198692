import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../pages/Main";
import Login from "../components/Users/Login";
import SetPassword from "../components/Users/SetPassword";
import ResetPassword from "../components/Users/ResetPassword";
import UpdateProfile from "../components/Users/UpdateProfile";

import UserListing from "../components/Users/UserListing";
import ClientListing from "../components/Masters/ClientListing";
import ContactListing from "../components/Masters/ContactListing";
import CareerListing from "../components/Masters/CareerListing";
import SubscribeListing from "../components/Masters/SubscribeListing";
import CategoryListing from "../components/Masters/CategoryListing";
import CategoryDetails from "../components/Masters/CategoryDetails";
import TagListing from "../components/Masters/TagListing";
import TestimonialListing from "../components/Testimonial/TestimonialListing";
import OurWorkListing from "../components/ourWork/OurWorkListing";
import OurWorkDetails from "../components/ourWork/OurWorkDetails";
import BlogListing from "../components/Blogs/BlogListing";
import store from "../store/index";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "",
      component: Main,
      children: [
        {
          path: "/login",
          name: "Login",
          component: Login,
          beforeEnter: (to, from, next) => {
            console.log(JSON.parse(localStorage.getItem("isAuthenticated")))
            if (JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Home" });
            } else {
              next();
            }
          },
        },
        {
          path: "/activate",
          name: "Activate",
          component: Login,
          beforeEnter: (to, from, next) => {
            if (JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Home" });
            } else {
              next();
            }
          },
        },
        {
          path: "/set-password/:code",
          name: "SetPassword",
          component: SetPassword,
          beforeEnter: (to, from, next) => {
            if (JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Home" });
            } else {
              next();
            }
          },
        },
        {
          path: "/reset-password/:code",
          name: "ResetPassword",
          component: ResetPassword,
          beforeEnter: (to, from, next) => {
            if (JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Home" });
            } else {
              next();
            }
          },
        },
        {
          path: "/",
          name: "ManageClient",
          component: ClientListing,
          beforeEnter: (to, from, next) => {
            console.log(localStorage.getItem("isAuthenticated"))
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = true;
              store.state.subMenu = true;
              next();
            }
          },
        },
        {
          path: "/manage-clients",
          name: "ManageClient",
          component: ClientListing,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = true;
              store.state.subMenu = true;
              next();
            }
          },
        },
        {
          path: "/view-contact-list",
          name: "ManageContact",
          component: ContactListing,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = false;
              store.state.subMenu = false;
              next();
            }
          },
        },
        {
          path: "/view-career-list",
          name: "ManageCareer",
          component: CareerListing,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = false;
              store.state.subMenu = false;
              next();
            }
          },
        },
        {
          path: "/view-subscibe-list",
          name: "ManageSubscribe",
          component: SubscribeListing,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = false;
              store.state.subMenu = false;
              next();
            }
          },
        },
        {
          path: "/manage-categories",
          name: "ManageCategory",
          component: CategoryListing,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = true;
              store.state.subMenu = true;
              next();
            }
          },
        },
        {
          path: "/manage-tags",
          name: "ManageTag",
          component: TagListing,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = true;
              store.state.subMenu = true;
              next();
            }
          },
        },
        {
          path: "/manage-testimonial",
          name: "ManageTestimonial",
          component: TestimonialListing,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = false;
              store.state.subMenu = false;
              next();
            }
          },
        },
        {
          path: "/manage-ourwork",
          name: "ManageOurWork",
          component: OurWorkListing,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = false;
              store.state.subMenu = false;
              next();
            }
          },
        },
        {
          path: "/category-details/:id",
          name: "CategoryDetail",
          component: CategoryDetails,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = false;
              store.state.subMenu = false;
              next();
            }
          },
        },
        {
          path: "/casestudy-details/:id",
          name: "CaseStudyDetails",
          component: OurWorkDetails,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = false;
              store.state.subMenu = false;
              next();
            }
          },
        },
        {
          path: "/blogs",
          name: "ManageBlog",
          component: BlogListing,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = false;
              store.state.subMenu = false;
              next();
            }
          },
        },
        {
          path: "/profile",
          name: "UpdateProfile",
          component: UpdateProfile,
          beforeEnter: (to, from, next) => {
            if (!JSON.parse(localStorage.getItem("isAuthenticated"))) {
              next({ name: "Login" });
            } else {
              store.state.dropdown = false;
              store.state.isSubmenuActive = false;
              store.state.subMenu = false;
              next();
            }
          },
        },
      ],
    },
  ],
});

export default router;

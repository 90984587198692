<template>
  <div id="app-onboarding" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-navbar-menu"
    data-mobile-item="#home-sidebar-menu-mobile" :class="{ 'is-pushed-full': $store.state.isSubmenuActive }">
    <div class="page-content-wrapper">
      <div class="page-content is-relative">
        <div class="page-title has-text-centered tabs-wrapper">
          <!-- Sidebar Trigger -->
          <sidebar />

          <div class="title-wrap">
            <h1 class="title is-4">Manage Categories </h1>
          </div>
          <toolbar />
        </div>

        <div class="datatable-toolbar">
          <div class="select">
            <select class="datatable-filter datatable-select form-control" data-filter="position"
              data-filter-type="default" v-model="perPage" @change="filterData">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          &nbsp;entries per page

          <div class="buttons">
            <div class="control mr-2">
              <input v-model="searchText" @keyup="filterData" class="input custom-text-filter" placeholder="Search..." />
              <div class="form-icon">
                <i data-feather="search"></i>
              </div>
            </div>
            <button class="button h-button is-primary is-elevated" @click="addEdit('add', '')">
              <span class="icon">
                <i aria-hidden="true" class="fas fa-plus"></i>
              </span>
              <span>Add</span>
            </button>
          </div>
        </div>

        <div class="page-content-inner">
          <!-- Datatable -->
          <div class="table-wrapper" data-simplebar>
            <table id="users-datatable" class="table is-datatable is-hoverable table-is-bordered">
              <thead>
                <tr>
                  <th>
                    <div class="control">#</div>
                  </th>
                  <th>Name</th>
                  <th>Slug URL</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <draggable v-model="categories" tag="tbody" @change="updateOrder">
                <tr data-id="0" v-for="(data, index) in this.categories" :key="data.id">
                  <td>
                    <div class="control">
                      {{ parseInt(perPage) * (currentPage - 1) + index + 1 }}
                    </div>
                  </td>
                  <td class="wrap">
                    <span class="
                                                                                has-dark-text
                                                                                dark-inverted
                                                                                is-font-alt is-weight-600
                                                                                rem-90
                                                                              ">
                      {{ data.name }}
                    </span>
                  </td>
                  <td>{{ data.slug_url }}</td>
                  <td>
                    <div class="field is-grouped">
                      <div class="control">
                        <div class="thin-switch" :class="data.is_active == 0 ? 'is-danger' : 'is-success'">
                          <input :id="'thin-switch' + index" class="input" type="checkbox" :checked="data.is_active == 1"
                            @click="updateStatusOrder(index, { 'is_active': data.is_active == 0 ? 1 : 0, 'category_id': data.category_id })" />
                          <label :for="'thin-switch' + index" class="slider"></label> {{
                            data.is_active == 0 ? "Inactive" : "Active"
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="buttons">
                      <button class="
                                                                                  button
                                                                                  is-light is-circle
                                                                                  hint--bubble hint--success hint--top
                                                                                " data-hint="Edit"
                        @click="addEdit('edit', index)">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>

                      <button @click="deleteCategory(index)" class="
                                                                                  button
                                                                                  is-light is-circle
                                                                                  hint--bubble hint--primary hint--top
                                                                                " data-hint="Delete">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>
                      <button @click="addCategoryDetail(data.category_id)" class="
                                                                                  button
                                                                                  is-light is-circle
                                                                                  hint--bubble hint--primary hint--top
                                                                                " data-hint="Add / Edit Detail">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
            <div class="section-placeholder" v-if="this.categories.length == 0">
              <div class="placeholder-content">
                <img class="light-image" src="img/illustrations/placeholders/search-4.svg" alt="">
                <img class="dark-image" src="img/illustrations/placeholders/search-4-dark.svg" alt="">
                <h3 class="dark-inverted">No data to show</h3>
                <p>There is currently no data to show in this list.</p>
              </div>
            </div>
          </div>

          <div id="paging-first-datatable" v-if="this.categories.length != 0" class="
                                                                      pagination
                                                                      datatable-pagination
                                                                      pagination-datatables
                                                                      text-center
                                                                    ">
            <pagination v-model="currentPage" :per-page="parseInt(perPage)" :records="total" @paginate="filterData" />
          </div>
        </div>
      </div>
    </div>
    <div id="add-edit-modal" class="modal h-modal" :class="{ 'is-active': this.show != 0 }">
      <div class="modal-background h-modal-close"></div>
      <div class="modal-content">
        <form id="category-form" data-vv-scope="category-form" v-on:submit.prevent="onSubmit('category-form')">
          <div class="modal-card">
            <header class="modal-card-head">
              <h3>
                {{
                  this.type == "add" ? "Add Category Details" : "Edit Category Details"
                }}
              </h3>
              <button class="h-modal-close ml-auto" aria-label="close" type="button">
                <i class="lnil lnil-close" @click="show = 0;"></i>
              </button>
            </header>
            <div class="modal-card-body">
              <div class="form-body">
                <!--Fieldset-->
                <div class="form-fieldset">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <div class="field">
                        <label>Category Name *</label>
                        <div class="control has-validation" :class="{
                          'has-error': errors.has('category-form.name'),
                        }">
                          <input type="text" class="input" name="name" v-model="category.name" placeholder=""
                            v-validate="'required|min:2|max:50'" @input="checkName()" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'category-form.name'
                              ),
                            }" />

                          <span v-show="errors.has('category-form.name')" class="error-text">{{
                            errors.first("category-form.name")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Slug URL *</label>
                        <div class="control has-validation" :class="{
                          'has-error': errors.has('category-form.slug_url'),
                        }">
                          <input type="text" class="input" name="slug_url" v-model="category.slug_url" placeholder=""
                            v-validate="'required|min:2|max:150'" @input="checkURL()" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'category-form.slug_url'
                              ),
                            }" />

                          <span v-show="errors.has('category-form.slug_url')" class="error-text">{{
                            errors.first("category-form.slug_url")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Category Title *</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('category-form.title'),
                            }">
                          <input type="text" class="input" name="title" v-model="category.title" placeholder=""
                            v-validate="'required|min:2|max:150'" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'category-form.title'
                              ),
                            }" />

                          <span v-show="errors.has('category-form.title')" class="error-text">{{
                            errors.first("category-form.title")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Category Overview *</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('category-form.overview'),
                            }">
                          <trumbowyg v-model="category.overview" :config="editorConfig"></trumbowyg>

                          <span v-show="errors.has('category-form.overview')" class="error-text">{{
                            errors.first("category-form.overview")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Meta Title *</label>
                        <div class="control has-validation" :class="{
                          'has-error': errors.has('category-form.meta_title'),
                        }">
                          <input type="text" class="input" name="meta_title" v-model="category.meta_title" placeholder=""
                            v-validate="'required|min:2|max:150'" @input="checkURL()" :class="{
                              input: true,
                              'is-danger': errors.has(
                                'category-form.meta_title'
                              ),
                            }" />

                          <span v-show="errors.has('category-form.meta_title')" class="error-text">{{
                            errors.first("category-form.meta_title")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label>Meta Keyword</label>
                        <div class="control has-validation" :class="{
                          'has-error': errors.has('category-form.meta_keyword'),
                        }">
                          <input type="text" class="input" name="meta_keyword" v-model="category.meta_keyword" placeholder=""
                            :class="{
                              input: true,
                              'is-danger': errors.has(
                                'category-form.meta_keyword'
                              ),
                            }" />

                          <span v-show="errors.has('category-form.meta_keyword')" class="error-text">{{
                            errors.first("category-form.meta_keyword")
                          }}</span>
                        </div>
                      </div>
                    </div>
                     <div class="column is-12">
                      <div class="field">
                        <label>Meta description</label>
                        <div class="control has-validation" :class="{
                              'has-error': errors.has('category-form.meta_description'),
                            }">
                          <textarea class="textarea" name="meta_description" rows="5" cols="30" v-model="category.meta_description"
                            placeholder="" :class="{
                              'is-danger': errors.has(
                                'category-form.meta_description'
                              ),
                            }" />


                          <span v-show="errors.has('category-form.meta_description')" class="error-text">{{
                            errors.first("category-form.meta_description")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-card-foot is-centered">
              <div class="buttons">
                <button type="button" @click="show = 0;" class="button h-button is-light is-dark-outlined">
                  <span class="icon">
                    <i class="lnir lnir-arrow-left rem-100"></i>
                  </span>
                  <span>Cancel</span>
                </button>
                <button type="submit" id="save-button" class="button h-button is-primary is-raised"
                  :class="{ 'is-loading': buttonloader['category-form'] }">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</template> 
<script>
import dataProcess from '../../util/dataProcess';

import draggable from "vuedraggable";
export default {
  name: "home",
  components: {
    draggable
  },
  data() {
    return {

      editorConfig: {
        lang: this.$store.state.appLocale.langId,
        autogrow: true,
        removeformatPasted: true,
        tagsToKeep: ['div', 'img'],
        btns: [
          ['bold', 'italic'], ['formatting'], ['link'], ['viewHTML'], ['fullscreen']
        ]
      },
      categories: [],
      bkCategories: [],
      buttonloader: [],
      total: 0,
      importCsv: true,
      category: {
        name: "",
        title: "",
        overview: "",
        slug_url: "",
          meta_description: "",
          meta_title: "",
          meta_keyword: "",
        lang_id: this.$store.state.appLocale.langId,
        device: this.$store.state.appDevice.device
      },
      type: "",
      index: "",
      show: 0,
      currentPage: 1,
      searchText: "",
      perPage: 10
    };
  },
  created() {
    this.$store.state.isDetailLoading = false;
  },
  mounted() {
    this.getData();
  },

  beforeMount() { },

  methods: {
    updateOrder() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .postBulkData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/category/update_data/display", this.categories)
        .then((response) => {
          if (response.status == 200) {
            this.$store.state.isDetailLoading = false;
          }
        });

    },
    getData() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .getData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/category/list")
        .then((response) => {
          if (response.status == 200) {
            if (response.data) {
              this.bkCategories = response.data.data;
              this.filterData();
            }
            this.$store.state.isDetailLoading = false;
          } else {
            this.total = 0;
          }
        });
    },
    updateStatusOrder(index, data) {
      if (confirm(
        "Are You Sure, want to " +
        (data.is_active == 1 ? "activate " : "inactivate") +
        " this category?"
      )) {
        dataProcess
          .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/category/update_data", data)
          .then((response) => {
            if (response.status == 200) {
              this.categories[index].is_active = data.is_active;
              if (data.is_active == 1) {
                this.$notyf.success("Activated successfully");
              } else {
                this.$notyf.error("Inactivated successfully");
              }
              this.$store.state.isDetailLoading = false;
            }
          });
      }
    },
    deleteCategory(index) {
      if (confirm("Are You Sure, Want To delete this category?")) {
        let data = this.categories[index];
        this.$store.state.isDetailLoading = true;
        dataProcess
          .deleteData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/category/destory/" + data.category_id)
          .then((response) => {
            if (response.status == 200) {
              let index1 = this.bkCategories.findIndex((el) => el.id == data.id);
              this.bkCategories.splice(index1, 1);
              this.filterData();
              this.$notyf.success("Deleted successfully");
              this.$store.state.isDetailLoading = false;
            }
          });
      }
    },
    filterData() {
      let searchText = this.searchText.toLowerCase().trim();
      let filterdData;
      if (searchText != "") {
        filterdData = this.bkCategories.filter(
          (el) =>
            (el.name && el.name.toLowerCase().indexOf(searchText) > -1)
        );
      } else {
        filterdData = this.bkCategories;
      }


      this.total = filterdData.length;
      if (this.currentPage > 1) {
        let noOfPages = Math.ceil(this.total / parseInt(this.perPage));
        this.currentPage = noOfPages >= this.currentPage ? this.currentPage : noOfPages;
      }
      this.categories = filterdData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );

    },
    addCategoryDetail(id) {
      this.$router.push({
        path: "/category-details/" + id,
      });
    },
    addEdit(type, index) {
      this.show = 1;
      this.type = type;
      if (type == "add") {
        this.category = {
          name: "",
          title: "",
          overview: "",
          slug_url: "",
          meta_description: "",
          meta_title: "",
          meta_keyword: "",
          lang_id: this.$store.state.appLocale.langId,
          device: this.$store.state.appDevice.device
        };
      } else {
        this.category = { ...this.categories[index] };
        this.category.overview = this.category.overview ? this.category.overview : '';
        this.category.meta_title = this.category.meta_title ? this.category.meta_title : '';
        this.category.meta_description = this.category.meta_description ? this.category.meta_description : '';
        this.category.meta_keyword = this.category.meta_keyword ? this.category.meta_keyword : '';
      }
    },
    onSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        this.$notyf.dismissAll();
        if (result && this.category.category_logo != '') {
          this.$store.state.isDetailLoading = true;
          this.buttonloader[scope] = true;
          if (this.type == "add") {
            this.category.createdby = this.$store.state.loginUserData.id;
            this.category.updatedby = this.$store.state.loginUserData.id;
            dataProcess
              .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/category/store", this.category)
              .then((response) => {
                if (response.status == 200) {
                  if (response.data) {
                    this.category.id = response.data;
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.success("Category user added successfully");
                    this.getData();
                  } else {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error("Error in inserting data");
                  }
                }
                if (response.status == 422 || response.status == 500) {
                  this.buttonloader[scope] = false;
                  this.show = 0;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error(response.data);
                }
              });
          } else {
            let postData = { ...this.category };
            dataProcess
              .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/category/update", postData)
              .then((response) => {
                if (response.status == 200) {
                  if (response.data) {
                    let index1 = this.bkCategories.findIndex(
                      (el) => el.id == this.category.id
                    );
                    this.bkCategories[index1] = response.data.data;
                    let index2 = this.categories.findIndex(
                      (el) => el.id == this.category.id
                    );
                    this.categories[index2] = response.data.data;
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.filterData();
                    this.$notyf.success("Category user updated successfully");
                  } else {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error("Error in inserting data");
                  }
                }
                if (response.status == 422 || response.status == 500) {
                  this.buttonloader[scope] = false;
                  this.show = 0;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error(response.data);
                }
              });
          }
        } else {
          this.buttonloader[scope] = false;
          this.$notyf.error("Correct form errors!!");
        }
      });
      this.filterData();
    },
    checkName() {
      console.log(this.category.name);
      if (this.category.name.trim() != "") {
        let index = this.bkCategories.findIndex(it => it.name.trim().toLowerCase() == this.category.name.trim().toLowerCase());
        console.log(index);
        if (index > -1) {
          if (this.type == "add") {
            this.$notyf.error(
              "Entered Category name already exist in database!!"
            );
            this.category.name = "";
          } else {
            if (this.bkCategories[index].id != this.category.id) {
              this.$notyf.error(
                "Entered Category name already exist in database!!"
              );
              this.category.name = "";
            }
          }
        }
      }
    },
    checkURL() {
      if (this.category.slug_url.trim() != "") {
        let index = this.bkCategories.findIndex(it => it.slug_url.trim().toLowerCase() == this.category.slug_url.trim().toLowerCase());
        if (index > -1) {
          if (this.type == "add") {
            this.$notyf.error(
              "Entered category URL already exist in database!!"
            );
            this.category.slug_url = "";
          } else {
            if (this.bkCategories[index].id != this.category.id) {
              this.$notyf.error(
                "Entered category URL already exist in database!!"
              );
              this.category.slug_url = "";
            }
          }
        }
      }
    },
    existAlready(el, i, resolve) {

      if (el.field1.trim() == '') {
        this.error.push('Row ' + (i + 1) + " -  Category name is empty, it is required field");
        resolve('');
      }
      else {
        if (this.bkCategories.findIndex(it => it.name.trim().toLowerCase() == el.field1.trim().toLowerCase()) > -1) {
          this.error.push('Row ' + (i + 1) + " - " + el.field1 + ' Category name already exist in database!!');
        }
        else {
          this.importData.push("('" + el.field1 + "'," + 1 + ")");
        }
        resolve('');
      }

    },
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.getData();
    },
    '$store.state.appDevice': function (newVal, oldVal) {
      this.getData();
    }
  },
  metaInfo: {
    title: "Skiy31 :: Manage categories",
  },
};
</script>
<style>
.vue-csv-uploader-part-two {
  display: none;
}

.center-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
}

.file-input {
  height: auto;
}

#import-modal .column {
  padding: 0px;
}

.form-fieldset {
  padding: 20px 0;
  max-width: 480px;
  margin: 0 38px;
}
</style>
<template>
  <div class="auth-wrapper">
    <!--Page body-->

    <div class="modern-login">
      <div class="underlay h-hidden-mobile h-hidden-tablet-p"></div>

      <div class="columns is-gapless is-vcentered">
        <div class="column is-relative is-8 h-hidden-mobile h-hidden-tablet-p">
          <div class="hero is-fullheight is-image">
            <div class="hero-body">
              <div class="container">
                <div class="columns">
                  <div class="column">
                    <img class="hero-image" src="img/illustrations/login/station.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4 is-relative">
          <a class="top-logo" href="index.html">
            <img class="light-image" src="img/logos/logo/logo.png" alt="" />
            <img class="dark-image" src="img/logos/logo/logo-light.png" alt="" />
          </a>
          <div class="is-form">
            <div class="hero-body">
              <div class="form-text">
                <h2>Reset Password</h2>
                <p>Password must strong one.</p>
              </div>
              <form id="password-form" autocomplete="off" data-vv-scope="password-form" v-on:submit.prevent="onSubmit('password-form')"
                class="login-wrapper">
                <div class="control has-validation" :class="{ 'has-error': errors.has('password-form.password') }">
                  <input type="password" class="input" autocomplete="off" name="password" v-model="password" placeholder=""
                    v-validate="'required|min:8|max:20|verify_password'" ref="password" :class="{
                      input: true,
                      'is-danger': errors.has('password-form.password'),
                    }" />
                  <span v-show="errors.has('password-form.password')" class="error-text">{{
                      errors.first("password-form.password")
                  }}</span>
                  <div class="auth-label">Password *</div>
                  <div class="auth-icon">
                    <i class="lnil lnil-envelope"></i>
                  </div>
                </div>
                <div class="control has-validation" :class="{
                  'has-error': errors.has('password-form.confirm_password'),
                }">
                  <input type="password" class="input" autocomplete="off" name="confirm_password" v-model="passwordMatch"
                    v-validate="'required|confirmed:password'" :class="{
                      input: true,
                      'is-danger': errors.has('password-form.confirm_password'),
                    }" />
                  <span v-show="errors.has('password-form.confirm_password')" class="error-text">{{
                      errors.first("password-form.confirm_password")
                  }}</span>
                  <div class="auth-label">Confirm Password *</div>
                  <div class="auth-icon">
                    <i class="lnil lnil-lock-alt"></i>
                  </div>
                </div>
                <div class="button-wrap has-help">
                  <button id="login-submit" type="submit" class="
                      button
                      h-button
                      is-big is-rounded is-primary is-bold is-raised
                    " :class="{ 'is-loading': buttonloader }">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { encrypt } from "../../helpers/security";

export default {
  name: "ResetPassword",

  data() {
    return {
      alertText: "",
      alert: false,
      password: "",
      passwordMatch: "",
      buttonloader: false,
      code: "",
      verified: 0,
    };
  },

  mounted() {
    this.$validator.extend('verify_password', {
      getMessage: field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
      validate: value => {
        //eslint-disable-next-line
       var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
        return strongRegex.test(value);
      }
    });
    this.code = this.$route.params.code;
  },

  methods: {
    onSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        this.$notyf.dismissAll();
        if (result) {
          this.buttonloader = true;
          this.$store.state.isDetailLoading = true;
          const data = {
            code: this.code,
          };
          axios
            .post(this.$path + "api/admin-check", data, {
              // headers,
            })
            .then((response) => {
              if (response.status == 200) {
                if (response.data.data.length > 0) {
                  let postData = {
                    password: this.password
                  };
                  axios
                    .post(
                      this.$path + "api/admin/" + response.data.data[0].id,
                      postData,
                      {
                        // headers,
                      }
                    )
                    .then((response) => {
                      if (response.status == 200) {
                        if (response.data) {
                          this.buttonloader = false;
                          this.$store.state.isDetailLoading = false;
                          this.$notyf.success("Password reset is successfull.");
                          this.$router.push({
                            path: "/login",
                          });
                        } else {
                          this.buttonloader = false;
                          this.$store.state.isDetailLoading = false;
                          this.$notyf.error("Error in inserting data.");
                        }
                      }
                      if (response.status == 422 || response.status == 500) {
                        this.buttonloader = false;
                        this.$store.state.isDetailLoading = false;
                        this.$notyf.error(response.data);
                      }
                    });
                } else {
                  this.buttonloader = false;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error("Error in setting password");
                }
              } else {
                this.buttonloader = false;
                this.$store.state.isDetailLoading = false;
                this.$notyf.error(response.data);
              }
            });
        } else {
          this.buttonloader = false;
          this.$notyf.error("Correct form errors!!");
        }
      });
    },
  },
  metaInfo: {
    title: "Skiy31 :: Reset Password",
  },
};
</script>

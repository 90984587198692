<template>
  <div id="huro-app" class="app-wrapper">
    
     <Navbar />
      <!--Full pageloader-->
        <!-- Pageloader -->
        <div class="pageloader is-full" :class="this.$store.state.isDetailLoading === true?'is-active':''"></div>
        <div class="infraloader is-full" :class="this.$store.state.isDetailLoading === true?'is-active':''"></div>
    
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "../layouts/Navbar";

export default {
  name: "Home",
  components: {
    Navbar,
  },
};
</script>
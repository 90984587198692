const CryptoJS = require("crypto-js")

export function encrypt(src) {
    return CryptoJS.AES.encrypt(src, process.env.VUE_APP_BASE_URL).toString()
}


export function decrypt(src) {
    const bytes = CryptoJS.AES.decrypt(src, process.env.VUE_APP_BASE_URL)
    const originalText = bytes.toString(CryptoJS.enc.Utf8)
    return originalText
}

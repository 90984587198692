<template>
  <div id="app-onboarding" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-navbar-menu"
    data-mobile-item="#home-sidebar-menu-mobile" :class="{ 'is-pushed-full': $store.state.isSubmenuActive }">
    <div class="page-content-wrapper">
      <div class="page-content is-relative">
        <div class="page-title has-text-centered tabs-wrapper">
          <!-- Sidebar Trigger -->
          <sidebar />

          <div class="title-wrap">
            <h1 class="title is-4">Manage Admin Users</h1>
          </div>
        </div>

        <div class="datatable-toolbar">
          <div class="select">
            <select class="datatable-filter datatable-select form-control" data-filter="position"
              data-filter-type="default" v-model="perPage" @change="filterData">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          &nbsp;entries per page

          <div class="buttons">
            <div class="control has-icon mr-2">
              <input v-model="searchText" @keyup="filterData" class="input custom-text-filter" placeholder="Search..."
                data-filter-target=".list-view-item" />
              <div class="form-icon">
                <i data-feather="search"></i>
              </div>
            </div>
            <button class="button h-button is-primary is-elevated" @click="addEditAdmin('add', '')">
              <span class="icon">
                <i aria-hidden="true" class="fas fa-plus"></i>
              </span>
              <span>Add User</span>
            </button>
          </div>
        </div>

        <div class="page-content-inner">
          <!-- Datatable -->
          <div class="table-wrapper" data-simplebar>
            <table id="users-datatable" class="table is-datatable is-hoverable table-is-bordered">
              <thead>
                <tr>
                  <th>
                    <div class="control">#</div>
                  </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr data-id="0" v-for="(data, index) in this.admins" :key="data.id">
                  <td>
                    <div class="control">
                      {{ (parseInt(perPage) * (currentPage - 1)) + index + 1 }}
                    </div>
                  </td>
                  <td>
                    <span class="
                        has-dark-text
                        dark-inverted
                        is-font-alt is-weight-600
                        rem-90
                      ">
                      {{ data.first_name + " " + data.last_name }}
                    </span>
                  </td>
                  <td>{{ data.email }}</td>
                  <td>{{ data.phone }}</td>
                  <td>
                    <div class="field is-grouped">
                      <div class="control">
                        <div class="thin-switch" :class="data.status == 0 ? 'is-danger' : 'is-success'">
                          <input :id="'thin-switch' + index" class="input" type="checkbox" :checked="data.status == 1"
                            @click="updateStatus(index, data.status == 0 ? 1 : 0)" />
                          <label :for="'thin-switch' + index" class="slider"></label> {{
                              data.status == 0 ? "Inactive" : "Active"
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="buttons">
                      <button class="
                          button
                          is-light is-circle
                          hint--bubble hint--success hint--top
                        " data-hint="Edit" @click="addEditAdmin('edit', index)">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button>

                      <!-- <button @click="deleteAdmin(index)" class="
                          button
                          is-light is-circle
                          hint--bubble hint--primary hint--top
                        " data-hint="Delete">
                        <span class="icon is-small">
                          <span class="fa-fw select-all fas"></span>
                        </span>
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="section-placeholder" v-if="this.admins.length == 0">
              <div class="placeholder-content">
                <img class="light-image" src="img/illustrations/placeholders/search-4.svg" alt="">
                <img class="dark-image" src="img/illustrations/placeholders/search-4-dark.svg" alt="">
                <h3 class="dark-inverted">No data to show</h3>
                <p>There is currently no data to show in this list.</p>
              </div>
            </div>
          </div>

          <div id="paging-first-datatable" class="
              pagination
              datatable-pagination
              pagination-datatables
              text-center
            ">
            <pagination v-model="currentPage" :per-page="parseInt(perPage)" :records="total" @paginate="filterData" />
          </div>
        </div>
      </div>
    </div>
    <div id="demo-centered-actions-modal" class="modal h-modal" :class="{ 'is-active': this.show != 0 }">
      <div class="modal-background h-modal-close"></div>
      <div class="modal-content">
        <form id="admin-form" data-vv-scope="admin-form" v-on:submit.prevent="onSubmit('admin-form')">
          <div class="modal-card">
            <header class="modal-card-head">
              <h3>
                {{
                    this.type == "add" ? "Add User Details" : "Edit User Details"
                }}
              </h3>
              <button class="h-modal-close ml-auto" aria-label="close" type="button">
                <i class="lnil lnil-close" @click="show = 0"></i>
              </button>
            </header>
            <div class="modal-card-body">
              <div class="form-body">
                <!--Fieldset-->
                <div class="form-fieldset">
                  <div class="columns is-multiline">
                    <div class="column is-6">
                      <div class="field">
                        <label>First Name *</label>
                        <div class="control has-icon has-validation" :class="{
                          'has-error': errors.has('admin-form.first_name'),
                        }">
                          <input type="text" class="input" name="first_name" data-vv-as="first name"
                            v-model="admin.first_name" placeholder="" v-validate="'required|min:2|max:50'" :class="{
                              input: true,
                              'is-danger': errors.has('admin-form.first_name'),
                            }" />
                          <div class="form-icon">
                            <i class="lnir lnir-user-alt"></i>
                          </div>
                          <span v-show="errors.has('admin-form.first_name')" class="error-text">{{
                              errors.first("admin-form.first_name")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Last Name *</label>
                        <div class="control has-icon has-validation" :class="{
                          'has-error': errors.has('admin-form.last_name'),
                        }">
                          <input type="text" class="input" name="last_name" data-vv-as="first name"
                            v-model="admin.last_name" placeholder="" v-validate="'required|min:1|max:50'" :class="{
                              input: true,
                              'is-danger': errors.has('admin-form.last_name'),
                            }" />
                          <div class="form-icon">
                            <i class="lnir lnir-user-alt"></i>
                          </div>
                          <span v-show="errors.has('admin-form.last_name')" class="error-text">{{
                              errors.first("admin-form.last_name")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Email Address *</label>
                        <div class="control has-icon has-validation" :class="{
                          'has-error': errors.has('admin-form.email'),
                        }">
                          <input type="text" class="input" name="email" @input="checkMail()" v-model="admin.email"
                            placeholder="" v-validate="'required|email|max:50'" :class="{
                              input: true,
                              'is-danger': errors.has('admin-form.email'),
                            }" />
                          <div class="form-icon">
                            <i class="lnir lnir-envelope"></i>
                          </div>
                          <span v-show="errors.has('admin-form.email')" class="error-text">{{
                              errors.first("admin-form.email")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label>Phone *</label>
                        <div class="control has-icon has-validation" :class="{
                          'has-error': errors.has('admin-form.phone'),
                        }">
                          <input type="text" class="input" name="phone" v-model="admin.phone" placeholder=""
                            v-validate="'required|integer|min:5|max:15'" :class="{
                              input: true,
                              'is-danger': errors.has('admin-form.phone'),
                            }" />
                          <div class="form-icon">
                            <i class="lnir lnir-phone"></i>
                          </div>
                          <span v-show="errors.has('admin-form.phone')" class="error-text">{{
                              errors.first("admin-form.phone")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-card-foot is-centered">
              <div class="buttons">
                <button type="button" @click="show = 0" class="button h-button is-light is-dark-outlined">
                  <span class="icon">
                    <i class="lnir lnir-arrow-left rem-100"></i>
                  </span>
                  <span>Cancel</span>
                </button>
                <button type="submit" id="save-button" class="button h-button is-primary is-raised"
                  :class="{ 'is-loading': buttonloader['admin-form'] }">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template> 
<script>
import axios from "axios";
import { encrypt } from "../../helpers/security";
export default {
  name: "home",

  data() {
    return {
      admins: [],
      bkAdmins: [],
      buttonloader: [],
      total: 0,
      admin: {
        first_name: "",
        last_name: "",
        phone: "",
        status: 1,
        email: "",
        role_id: 3,
        is_activated: 0,
        is_logged_once: 0,
        is_super: 0,
      },
      type: "",
      index: "",
      show: 0,
      currentPage: 1,
      searchText: "",
      perPage: 10,
    };
  },
  created() {
    this.$store.state.isDetailLoading = false;
  },
  mounted() {
    this.$store.state.isDetailLoading = true;
    axios
      .get(this.$path + "admins", {
        // headers,
      })
      .then((response) => {
        if (response.status == 200) {
          if (response.data) {
            this.bkAdmins = response.data;
            this.total = this.bkAdmins.length;
            this.admins = this.bkAdmins.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage
            );
          }
          this.$store.state.isDetailLoading = false;
        } else {
          this.total = 0;
        }
      });
  },

  beforeMount() { },

  methods: {
    updateStatus(index, status) {
      if (
        confirm(
          "Are You Sure, Want To " +
          (status == 1 ? "Activate " : "Inactivate") +
          " this user?"
        )
      ) {
        let data = this.admins[index];
        let postData = { status: status, updatedby: this.$store.state.loginUserData.id };
        this.$store.state.isDetailLoading = true;
        axios
          .put(this.$path + "admin/" + data.id, postData, {
            // headers,
          })
          .then((response) => {
            if (response.status == 200) {
              this.admins[index].status = status;
              if (status == 1) {
                this.$notyf.success("Activated successfully");
              } else {
                this.$notyf.error("Inactivated successfully");
              }

              this.$store.state.isDetailLoading = false;
            }
          });
      }
    },
    deleteAdmin(index) {
      if (confirm("Are You Sure, Want To delete this user?")) {
        let data = this.admins[index];
        axios
          .delete(this.$path + "admin/" + data.id, {
            // headers,
          })
          .then((response) => {
            if (response.status == 200) {
              let index1 = this.bkAdmins.findIndex((el) => el.id == data.id);
              this.bkAdmins.splice(index1, 1);
              this.filterData();
              this.$notyf.success("Deleted successfully");
              this.$store.state.isDetailLoading = false;
            }
          });
      }
    },
    filterData() {
      let searchText = this.searchText.toLowerCase().trim();
      let filterdData;
      if (searchText != "") {
        filterdData = this.bkAdmins.filter(
          (el) =>
            el.first_name.toLowerCase().indexOf(searchText) > -1 ||
            el.last_name.toLowerCase().indexOf(searchText) > -1 ||
            (el.first_name + " " + el.last_name)
              .toLowerCase()
              .indexOf(searchText) > -1 ||
            el.email.toLowerCase().indexOf(searchText) > -1 ||
            el.phone.toLowerCase().indexOf(searchText) > -1
        );
      } else {
        filterdData = this.bkAdmins;
      }
      this.total = filterdData.length;
      if (this.currentPage > 1) {
        let noOfPages = Math.ceil(this.total / parseInt(this.perPage));
        this.currentPage = noOfPages >= this.currentPage ? this.currentPage : noOfPages;
      }
      this.admins = filterdData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    addEditAdmin(type, index) {
      this.show = 1;
      this.type = type;
      if (type == "add") {
        this.admin = {
          first_name: "",
          last_name: "",
          phone: "",
          status: 1,
          email: "",
          role_id: 2,
          is_activated: 0,
          is_logged_once: 0,
          is_super: 0,
        };
      } else {
        this.admin = { ...this.admins[index] };
      }
    },
    onSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        this.$notyf.dismissAll();
        if (result) {
          this.$store.state.isDetailLoading = true;
          this.buttonloader[scope] = true;
          if (this.type == "add") {
            let randomText = (Math.random() + 1).toString(36).substring(4);
            this.admin.dPassword = randomText;
            this.admin.password = encrypt(randomText);
            this.admin.code = (Math.random() + 1).toString(36).substring(4);
            this.admin.activationUrl =
              process.env.VUE_APP_URL + "activate?code=" + this.admin.code;
            
          } else {
            let postData = { ...this.admin };
            delete postData.id;
            postData.updatedby = this.$store.state.loginUserData.id;
            axios
              .put(this.$path + "admin/" + this.admin.id, postData, {
                // headers,
              })
              .then((response) => {
                if (response.status == 200) {
                  if (response.data) {
                    let index1 = this.bkAdmins.findIndex(
                      (el) => el.id == this.admin.id
                    );
                    this.bkAdmins[index1] = this.admin;
                    let index2 = this.admins.findIndex(
                      (el) => el.id == this.admin.id
                    );
                    this.admins[index2] = this.admin;
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.filterData();
                    this.$notyf.success("Admin user updated successfully");
                  } else {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error("Error in inserting data");
                  }
                }
                if (response.status == 422 || response.status == 500) {
                  this.buttonloader[scope] = false;
                  this.show = 0;
                  this.$store.state.isDetailLoading = false;
                  this.$notyf.error(response.data);
                }
              });
          }
        } else {
          this.buttonloader[scope] = false;
          this.$notyf.error("Correct form errors!!");
        }
      });
      this.filterData();
    },
    checkMail() {
      if (this.admin.email.trim() != "") {
        const data = {
          email: this.admin.email.toLowerCase(),
        };
        axios
          .post(this.$path + "api/admin-check", data, {
            // headers,
          })
          .then((response) => {
            if (response.status == 200) {
              if (response.data.length > 0) {
                if (this.type == "add") {
                  this.$notyf.error(
                    "Entered email already exist in database!!"
                  );
                  this.admin.email = "";
                } else {
                  if (response.data[0].id != this.admin.id) {
                    this.$notyf.error(
                      "Entered email already exist in database!!"
                    );
                    this.admin.email = "";
                  }
                }
              }
            }
          });
      }
    },
  },

  metaInfo: {
    title: "Skiy31 :: Manage admin users",
  },
};
</script>
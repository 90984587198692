<template>
  <div id="app-onboarding" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-navbar-menu"
    data-mobile-item="#home-sidebar-menu-mobile" :class="{ 'is-pushed-full': $store.state.isSubmenuActive }">
    <div class="page-content-wrapper">
      <div class="page-content is-relative">
        <div class="page-title has-text-centered tabs-wrapper">
          <!-- Sidebar Trigger -->
          <sidebar />

          <div class="title-wrap">
            <h1 class="title is-4">Add / Edit Service Details</h1>
          </div>
          <toolbar />
        </div>

        <div class="page-content-inner">
          <form id="category-form" data-vv-scope="category-form" v-on:submit.prevent="onSubmit('category-form')">
            <div class="list-view-toolbar stuck-header" :class="{ 'is-stuck': is_stuck }">

              <div class="buttons">
                <div class="control mr-2">

                </div>
                <button type="submit" id="save-button" class="button h-button is-primary is-raised"
                  :class="{ 'is-loading': buttonloader['category-form'] }">

                  <span>Submit</span>
                </button>
              </div>
            </div>
            <div class="columns">
              <div class="column is-12">
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Service Section 1</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns">
                      <div class="column is-4">
                        <div class="field">
                          <label>Title</label>
                          <div class="control has-validation" :class="{
                            'has-error': errors.has('category-form.section1_title'),
                          }">
                            <input type="text" class="input" name="section1_title" v-model="section_1.title"
                              placeholder="" v-validate="'min:2|max:550'" :class="{
                                input: true,
                                'is-danger': errors.has(
                                  'category-form.section1_title'
                                ),
                              }" />
                          </div>
                        </div>
                      </div>
                      <div class="column is-8">
                        <div class="field">
                          <label>Description</label>
                          <div class="control has-validation">
                            <trumbowyg  v-model="section_1.description" :config="editorConfig">
                            </trumbowyg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Service our work  title</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns">
                      <div class="column is-8">
                        <div class="field">
                          <label>Title</label>
                          <div class="control has-validation" :class="{
                            'has-error': errors.has('category-form.our_work_title'),
                          }">
                            <input type="text" class="input" name="our_work_title" v-model="our_work_title"
                              placeholder="" v-validate="'min:2|max:300'" :class="{
                                input: true,
                                'is-danger': errors.has(
                                  'category-form.our_work_title'
                                ),
                              }" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Service UI UX Game Content</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card columns">
                          <div class="column is-4">
                            <div class="field">
                              <label>Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.ui_ux_title'),
                              }">
                                <input type="text" class="input" name="ui_ux_title" v-model="ui_ux.title" placeholder=""
                                  v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.ui_ux_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                          <div class="column is-8">
                            <div class="columns is-multiline">
                              <div class="column is-6">
                                <div class="field">
                                  <label>Subscribe Title</label>
                                  <div class="control has-validation" :class="{
                                    'has-error': errors.has('category-form.ui_ux_subscribe_title'),
                                  }">
                                    <input type="text" class="input" name="ui_ux_subscribe_title"
                                      v-model="ui_ux.subscribe.head" placeholder="" v-validate="'min:2|max:550'" :class="{
                                        input: true,
                                        'is-danger': errors.has(
                                          'category-form.ui_ux_subscribe_title'
                                        ),
                                      }" />
                                  </div>
                                </div>
                              </div>
                              <div class="column is-6">
                                <div class="field">
                                  <label>Subscribe Input Text</label>
                                  <div class="control has-validation" :class="{
                                    'has-error': errors.has('category-form.ui_ux_subscribe_text'),
                                  }">
                                    <input type="text" class="input" name="ui_ux_subscribe_text"
                                      v-model="ui_ux.subscribe.text" placeholder="" v-validate="'min:2|max:550'" :class="{
                                        input: true,
                                        'is-danger': errors.has(
                                          'category-form.ui_ux_subscribe_text'
                                        ),
                                      }" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card columns mt-2">
                          <div class="column is-6">
                            <div class="field">
                              <label>Notification Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.ui_ux_notification_title'),
                              }">
                                <input type="text" class="input" name="ui_ux_notification_title"
                                  v-model="ui_ux.notification.head" placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.ui_ux_notification_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                          <div class="column is-3">
                            <div class="field">
                              <label>Notification Button 1 Text</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.ui_ux_notification_b1_text'),
                              }">
                                <input type="text" class="input" name="ui_ux_notification_b1_text"
                                  v-model="ui_ux.notification.b1_text" placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.ui_ux_notification_b1_text'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                          <div class="column is-3">
                            <div class="field">
                              <label>Notification Button 2 Text</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.ui_ux_notification_b2_text'),
                              }">
                                <input type="text" class="input" name="ui_ux_notification_b2_text"
                                  v-model="ui_ux.notification.b2_text" placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.ui_ux_notification_b2_text'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card columns mt-2">
                          <div class="field column is-12">
                            <label>Video Link</label>
                            <div class="control has-validation" :class="{
                              'has-error': errors.has('category-form.ui_ux_video_link'),
                            }">
                              <input type="text" class="input" name="ui_ux_video_link" v-model="ui_ux.video_link"
                                placeholder="" v-validate="'min:2|max:550'" :class="{
                                  input: true,
                                  'is-danger': errors.has(
                                    'category-form.ui_ux_video_link'
                                  ),
                                }" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card columns mt-2">
                          <div class="column is-4">
                            <div class="field">
                              <label>Content Section Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.ui_ux_content_title'),
                              }">
                                <input type="text" class="input" name="ui_ux_content_title" v-model="ui_ux.content.head"
                                  placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.ui_ux_content_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                          <div class="column is-8">
                            <div class="field">
                              <label>Content Section Description</label>
                              <div class="control has-validation">
                                <trumbowyg  v-model="ui_ux.content.text" :config="editorConfig">
                                </trumbowyg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 2 (Card Listing)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card">
                          <div class="column is-6">
                            <div class="field">
                              <label>Section 2 Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_2_title'),
                              }">
                                <input type="text" class="input" name="section_2_title" v-model="section_2.title"
                                  placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_2_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label>Section 2 Sub Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_2_sub_title'),
                              }">
                                <input type="text" class="input" name="section_2_sub_title" v-model="section_2.sub_title"
                                  placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_2_sub_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in section_2.data" :key="index">
                            <div class="column is-4">
                              <div class="field">
                                <label>Card {{ index + 1 }} Title</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.item' + index),
                                }">
                                  <input type="text" class="input" :name="'item' + index" v-model="item.head"
                                    placeholder="" v-validate="'min:2|max:550'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'category-form.item' + index
                                      ),
                                    }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-7">
                              <div class="field">
                                <label>Card {{ index + 1 }} Text</label>
                                <div class="control has-validation">
                                  <trumbowyg  v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_2.data.length > 1" @click="removeItem(section_2.data, index)"
                                class="
                                                                                                                                                                                            button
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_2.data, section_2_data)"
                            class="
                                                                                                                                                                                            button is-pulled-right
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 3 (Bullet List)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card columns is-multiline ml-1">
                          <div class="column is-6">
                            <div class="field">
                              <label>Section 3 Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_3_title'),
                              }">
                                <input type="text" class="input" name="section_3_title" v-model="section_3.title"
                                  placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_3_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label>Section 3 Sub Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_3_sub_title'),
                              }">
                                <input type="text" class="input" name="section_3_sub_title" v-model="section_3.sub_title"
                                  placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_3_sub_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in section_3.data" :key="index">
                            <div class="column is-2">
                              <div class="field">
                                <label>List {{ index + 1 }} Title</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_3_item' + index),
                                }">
                                  <input type="text" class="input" :name="'section_3_item' + index" v-model="item.head"
                                    placeholder="" v-validate="'min:2|max:550'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'category-form.item' + index
                                      ),
                                    }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-5">
                              <div class="field">
                                <label>List {{ index + 1 }} Text</label>
                                <div class="control has-validation">
                                  <trumbowyg  v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label>List {{ index + 1 }} Icon</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_3_item_logo' + index),
                                }">

                                  <div class="field is-grouped">
                                    <div class="control">
                                      <div class="file has-name is-fullwidth">
                                        <label class="file-label">
                                          <input class="file-input" type="file"
                                            @change="previewFiles($event, item, 'icon')" placeholder="">
                                          <span class="file-cta">
                                            <span class="file-icon">
                                              <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                            </span>
                                            <span class="file-label">
                                              Choose a file…
                                            </span>
                                          </span>
                                          <span class="file-name light-text">
                                            {{ item.icon ? 'selected' : '' }}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    <img width="100" style="background-color: grey;" class="ml-1" v-if="item.icon"
                                      :src="item.icon">
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_3.data.length > 1" @click="removeItem(section_3.data, index)"
                                class="
                                                                                                                                                                                            button
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_3.data, section_3_data)"
                            class="
                                                                                                                                                                                            button is-pulled-right
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 4 (Tools)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card">
                          <div class="column is-6">
                            <div class="field">
                              <label>Tools Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_4_title'),
                              }">
                                <input type="text" class="input" name="section_4_title" v-model="section_4.title"
                                  placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_4_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in section_4.data" :key="index">
                            <div class="column is-3">
                              <div class="field">
                                <label>Tools List {{ index + 1 }} Title</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_4_item' + index),
                                }">
                                  <input type="text" class="input" :name="'section_4_item' + index" v-model="item.head"
                                    placeholder="" v-validate="'min:2|max:550'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'category-form.item' + index
                                      ),
                                    }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-8">
                              <div class="column is-12">
                                <div class="l-card mt-2">
                                  <div class="columns is-multiline" v-for="(item2, index1) in section_4.data[index].item"
                                    :key="index1">
                                    <div class="column is-3">
                                      <div class="field">
                                        <label>Tool Name</label>
                                        <div class="control has-validation" :class="{
                                          'has-error': errors.has('category-form.section_4_item2' + index1),
                                        }">
                                          <input type="text" class="input" :name="'section_4_item2' + index1"
                                            v-model="item2.text" placeholder="" v-validate="'min:2|max:550'" :class="{
                                              input: true,
                                              'is-danger': errors.has(
                                                'category-form.section_4_item2' + index1
                                              ),
                                            }" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="column is-7">
                                      <div class="field">
                                        <label>Tool Icon</label>
                                        <div class="control has-validation" :class="{
                                          'has-error': errors.has('category-form.section_4_item2_logo' + index1),
                                        }">

                                          <div class="field is-grouped">
                                            <div class="control">
                                              <div class="file has-name is-fullwidth">
                                                <label class="file-label">
                                                  <input class="file-input" type="file"
                                                    @change="previewFiles($event, item2, 'icon')" placeholder="">
                                                  <span class="file-cta">
                                                    <span class="file-icon">
                                                      <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                                    </span>
                                                    <span class="file-label">
                                                      Choose a file…
                                                    </span>
                                                  </span>
                                                  <span class="file-name light-text">
                                                    {{ item2.icon ? 'selected' : '' }}
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <img width="100" style="background-color: grey;" class="ml-1"
                                              v-if="item2.icon" :src="item2.icon">
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                    <div class="column is-1">
                                      <a v-if="section_4.data[index].item.length > 1"
                                        @click="removeItem(section_4.data[index].item, index1)"
                                        class="
                                                                                                                                                                                            button
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                                        data-hint="Remove Item">

                                        <i class="lnil lnil-close"></i>
                                      </a>
                                    </div>
                                  </div>
                                  <a @click="addItem(section_4.data[index].item, section_4_items)"
                                    class="
                                                                                                                                                                                            button is-pulled-right
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                                    data-hint="Add">
                                    <span class="icon is-small">
                                      <span class="fa-fw select-all fas"></span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_4.data.length > 1" @click="removeItem(section_4.data, index)"
                                class="
                                                                                                                                                                                            button
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_4.data, section_4_data)"
                            class="
                                                                                                                                                                                            button is-pulled-right
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 5 (Inner CTA)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns">
                      <div class="column is-4">
                        <div class="field">
                          <label>Inner CTA Title</label>
                          <div class="control has-validation" :class="{
                            'has-error': errors.has('category-form.section_5_title'),
                          }">
                            <input type="text" class="input" name="section_5_title" v-model="section_5.head"
                              placeholder="" v-validate="'min:2|max:550'" :class="{
                                input: true,
                                'is-danger': errors.has(
                                  'category-form.section_5_title'
                                ),
                              }" />
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Inner CTA Text</label>
                          <div class="control has-validation">
                            <trumbowyg  v-model="section_5.text" :config="editorConfig"></trumbowyg>
                          </div>
                        </div>
                      </div>
                      <div class="column is-2">
                        <div class="field">
                          <label>Inner CTA Button text</label>
                          <div class="control has-validation" :class="{
                            'has-error': errors.has('category-form.section_5_button'),
                          }">
                            <input type="text" class="input" name="section_5_button" v-model="section_5.button_text"
                              placeholder="" v-validate="'min:2|max:550'" :class="{
                                input: true,
                                'is-danger': errors.has(
                                  'category-form.section_5_button'
                                ),
                              }" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 6 (Work Flow)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card">
                          <div class="column is-6">
                            <div class="field">
                              <label>Work Flow Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_6_title'),
                              }">
                                <input type="text" class="input" name="section_6_title" v-model="section_6.title"
                                  placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_6_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in section_6.data" :key="index">
                            <div class="column is-4">
                              <div class="field">
                                <label>Work Flow {{ index + 1 }} Title</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_6_item' + index),
                                }">
                                  <input type="text" class="input" :name="'section_6_item' + index" v-model="item.head"
                                    placeholder="" v-validate="'min:2|max:550'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'category-form.item' + index
                                      ),
                                    }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-7">
                              <div class="field">
                                <label>Work Flow {{ index + 1 }} Text</label>
                                <div class="control has-validation">
                                  <trumbowyg  v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_6.data.length > 1" @click="removeItem(section_6.data, index)"
                                class="
                                                                                                                                                                                            button
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_6.data, section_2_data)"
                            class="
                                                                                                                                                                                            button is-pulled-right
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 7 (Values)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in section_7" :key="index">
                            <div class="column is-3">
                              <div class="field">
                                <label>Value {{ index + 1 }} Heading</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_7_item' + index),
                                }">
                                  <input type="text" class="input" :name="'section_7_item' + index" v-model="item.head"
                                    placeholder="" v-validate="'min:2|max:550'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'category-form.item' + index
                                      ),
                                    }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label>Value {{ index + 1 }} Text</label>
                                <div class="control has-validation">
                                  <trumbowyg  v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label>Value {{ index + 1 }} Image</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_7_item_logo' + index),
                                }">

                                  <div class="field is-grouped">
                                    <div class="control">
                                      <div class="file has-name is-fullwidth">
                                        <label class="file-label">
                                          <input class="file-input" type="file"
                                            @change="previewFiles($event, item, 'image')" placeholder="">
                                          <span class="file-cta">
                                            <span class="file-icon">
                                              <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                            </span>
                                            <span class="file-label">
                                              Choose a file…
                                            </span>
                                          </span>
                                          <span class="file-name light-text">
                                            {{ item.image ? 'selected' : '' }}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    <img width="100" style="background-color: grey;" class="ml-1" v-if="item.image"
                                      :src="item.image">
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_7.length > 1" @click="removeItem(section_7, index)"
                                class="
                                                                                                                                                                                          button
                                                                                                                                                                                          is-light is-circle
                                                                                                                                                                                          hint--bubble hint--primary hint--top
                                                                                                                                                                                        "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_7, section_7_data)"
                            class="
                                                                                                                                                                                          button is-pulled-right
                                                                                                                                                                                          is-light is-circle
                                                                                                                                                                                          hint--bubble hint--primary hint--top
                                                                                                                                                                                        "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 8 (FAQ)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card">
                          <div class="column is-6">
                            <div class="field">
                              <label>FAQ Section Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_8_title'),
                              }">
                                <input type="text" class="input" name="section_8_title" v-model="section_8.title"
                                  placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_8_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in section_8.data" :key="index">
                            <div class="column is-4">
                              <div class="field">
                                <label>Question {{ index + 1 }}</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_8_item' + index),
                                }">
                                  <input type="text" class="input" :name="'section_8_item' + index" v-model="item.head"
                                    placeholder="" v-validate="'min:2|max:550'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'category-form.item' + index
                                      ),
                                    }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-7">
                              <div class="field">
                                <label>Answer {{ index + 1 }}</label>
                                <div class="control has-validation">
                                  <trumbowyg  v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_8.data.length > 1" @click="removeItem(section_8.data, index)"
                                class="
                                                                                                                                                                                          button
                                                                                                                                                                                          is-light is-circle
                                                                                                                                                                                          hint--bubble hint--primary hint--top
                                                                                                                                                                                        "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_8.data, section_2_data)"
                            class="
                                                                                                                                                                                          button is-pulled-right
                                                                                                                                                                                          is-light is-circle
                                                                                                                                                                                          hint--bubble hint--primary hint--top
                                                                                                                                                                                        "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 9 (Brand Second Card)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card columns ml-1">
                          <div class="column is-6">
                            <div class="field">
                              <label>Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_9_title'),
                              }">
                                <input type="text" class="input" name="section_9_title" v-model="section_9.title"
                                  placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_9_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label>Tail Text</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_9_tail_text'),
                              }">
                                <input type="text" class="input" name="section_9_tail_text" v-model="section_9.tail_text"
                                  placeholder="" v-validate="'min:2|max:550'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_9_tail_text'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in section_9.data" :key="index">
                            <div class="column is-4">
                              <div class="field">
                                <label>Card {{ index + 1 }} Title</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_9_item' + index),
                                }">
                                  <input type="text" class="input" :name="'section_9_item' + index" v-model="item.head"
                                    placeholder="" v-validate="'min:2|max:550'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'category-form.item' + index
                                      ),
                                    }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-7">
                              <div class="field">
                                <label>Card {{ index + 1 }} Text</label>
                                <div class="control has-validation">
                                  <trumbowyg  v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_9.data.length > 1" @click="removeItem(section_9.data, index)"
                                class="
                                                                                                                                                                                          button
                                                                                                                                                                                          is-light is-circle
                                                                                                                                                                                          hint--bubble hint--primary hint--top
                                                                                                                                                                                        "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_9.data, section_2_data)"
                            class="
                                                                                                                                                                                          button is-pulled-right
                                                                                                                                                                                          is-light is-circle
                                                                                                                                                                                          hint--bubble hint--primary hint--top
                                                                                                                                                                                        "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
import dataProcess from '../../util/dataProcess';

export default {
  name: "CategoryDetail",
  components: {

  },
  data() {
    return {
      is_stuck: false,
      
      editorConfig: {
       lang: this.$store.state.appLocale.langId,
        autogrow: true,
        removeformatPasted: true,
        tagsToKeep: ['div', 'img'],
        btns: [
          ['bold', 'italic'], ['formatting'], ['link'], ['viewHTML'], ['fullscreen']
        ]
      },
      buttonloader: [],
      category: {
        section_1: "",
        our_work_title: "",
        ui_ux: "",
        section_2: "",
        section_3: "",
        section_4: "",
        section_5: "",
        section_6: "",
        section_7: "",
        section_8: "",
        section_9: "",
        lang_id: this.$store.state.appLocale.langId,
        device: this.$store.state.appDevice.device,
        category_id: ''
      },
      section_1: {
        title: "",
        description: ""
      },
      our_work_title:"",
      ui_ux: {
        title: "",
        subscribe: {
          head: "",
          input_text: ""
        },
        notification: {
          head: "",
          b1_text: "",
          b2_text: ""
        },
        video_link: "",
        content: {
          head: "",
          text: ""
        }
      },
      section_2: {
        title: "",
        sub_title: "",
        data: [
        ]
      },
      section_2_data: { head: '', text: '' },
      section_3: {
        title: "",
        sub_title: "",
        data: [
        ]
      },
      section_3_data: { icon: '', head: '', text: '' },
      section_4: { title: '', data: [] },
      section_4_data: { head: '', item: [] },
      section_4_items: { icon: '', text: '' },
      section_5: { head: "", text: "", button_text: "" },
      section_6: {
        title: "",
        data: [
        ]
      },
      section_7: [],
      section_7_data: { head: '', text: '', image: '' },
      section_8: {
        title: "",
        data: [
        ]
      },
      section_9: {
        title: "",
        data: [
        ],
        tail_text: ""
      },
      index: "",
      type: 'edit',
      category_id: ""
    };
  },
  created() {
    this.$store.state.isDetailLoading = false;
    this.category_id = this.$route.params.id;
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.getData();
  },

  beforeMount() { },

  methods: {
    handleScroll(event) {
      let header = document.getElementsByClassName("stuck-header")[0];
      if (header) {
        this.is_stuck = window.scrollY > 80 ? true : false;
      }
    },
    getData() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .getData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/category/detail/" + this.category_id)
        .then((response) => {
          if (response.status == 200) {
            this.intializeSections(response.data);
            this.$store.state.isDetailLoading = false;
          }
        });
    },

    onSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        this.$notyf.dismissAll();
        if (result) {

          this.buttonloader[scope] = true;
          this.category.section_1 = (this.section_1.title != '' || this.section_1.description != '') ? JSON.stringify(this.section_1) : '';
          this.category.our_work_title = this.our_work_title != '' ? JSON.stringify(this.our_work_title) : '';
          this.category.ui_ux = (this.ui_ux.title != '') ? JSON.stringify(this.ui_ux) : '';
          this.category.section_2 = (this.section_2.title != '' || (this.section_2.data.length > 0 && this.section_2.data[0].head != '')) ? JSON.stringify(this.section_2) : '';
          this.category.section_3 = (this.section_3.title != '' || (this.section_3.data.length > 0 && this.section_3.data[0].head != '')) ? JSON.stringify(this.section_3) : '';
          this.category.section_4 = (this.section_4.title != '' || (this.section_4.data.length > 0 && this.section_4.data[0].head != '')) ? JSON.stringify(this.section_4) : '';
          this.category.section_5 = (this.section_5.head != '' || this.section_5.text != '') ? JSON.stringify(this.section_5) : '';
          this.category.section_6 = (this.section_6.title != '' || (this.section_6.data.length > 0 && this.section_6.data[0].head != '')) ? JSON.stringify(this.section_6) : '';
          this.category.section_7 = (this.section_7.length > 0 && this.section_7[0].head != '') ? JSON.stringify(this.section_7) : '';
          this.category.section_8 = (this.section_8.title != '' || (this.section_8.data.length > 0 && this.section_8.data[0].head != '')) ? JSON.stringify(this.section_8) : '';
          this.category.section_9 = (this.section_9.title != '' || (this.section_9.data.length > 0 && this.section_9.data[0].head != '')) ? JSON.stringify(this.section_9) : '';
          this.category.category_id = this.category_id;
          if (this.category.section_1 != '' || this.category.ui_ux != '' || this.category.section_2 != '' || this.category.section_3 != '' || this.category.section_4 != '' || this.category.section_5 != '' || this.category.section_6 != '' || this.category.section_7 != '' || this.category.section_8 != '' || this.category.section_9 != '') {
            if (this.type == "add") {
              this.$store.state.isDetailLoading = true;
              dataProcess
                .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/category/store-detail", this.category)
                .then((response) => {
                  if (response.status == 200) {
                    if (response.data) {
                      this.buttonloader[scope] = false;
                      this.$store.state.isDetailLoading = false;
                      this.$notyf.success("Category Detail added successfully");
                      this.getData();
                    } else {
                      this.buttonloader[scope] = false;
                      this.$store.state.isDetailLoading = false;
                      this.$notyf.error("Error in inserting data");
                    }
                  }
                  if (response.status == 422 || response.status == 500) {
                    this.buttonloader[scope] = false;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error(response.data);
                  }
                });
            } else {
              this.$store.state.isDetailLoading = true;
              let postData = { ...this.category };
              dataProcess
                .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/category/update-detail", postData)
                .then((response) => {
                  if (response.status == 200) {
                    if (response.data) {
                      this.intializeSections(response.data);
                      this.buttonloader[scope] = false;
                      this.$store.state.isDetailLoading = false;
                      this.$notyf.success("Category deatil updated successfully");
                    } else {
                      this.buttonloader[scope] = false;
                      this.$store.state.isDetailLoading = false;
                      this.$notyf.error("Error in inserting data");
                    }
                  }
                  if (response.status == 422 || response.status == 500) {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error(response.data);
                  }
                });
            }
          }
          else {
            this.buttonloader[scope] = false;
            this.$notyf.error("Please enter atleast one section details");
          }
        } else {
          this.buttonloader[scope] = false;
          this.$notyf.error("Correct form errors!!");
        }
      });
    },
    intializeSections(data) {
      if (data.data.length > 0) {
        this.type = 'edit';
        this.category = data.data[0];
        this.section_1 = this.category.section_1 ? JSON.parse(this.category.section_1) : this.section_1;
        this.our_work_title = this.category.our_work_title ? JSON.parse(this.category.our_work_title) : this.our_work_title;
        this.ui_ux = this.category.ui_ux ? JSON.parse(this.category.ui_ux) : this.ui_ux;
        this.section_2 = this.category.section_2 ? JSON.parse(this.category.section_2) : this.section_2;
        this.section_3 = JSON.parse(this.category.section_3);
        this.section_4 = this.category.section_4 ? JSON.parse(this.category.section_4) : this.section_4;
        this.section_4_data = { head: '', item: [{ ...this.section_4_items }] };
        this.section_4.data = (this.section_4.data.length > 0 && this.section_4.data[0].item) ? this.section_4.data : [{ ...this.section_4_data }];
        this.section_5 = JSON.parse(this.category.section_5);
        this.section_6 = JSON.parse(this.category.section_6);
        this.section_7 = JSON.parse(this.category.section_7);
        this.section_8 = JSON.parse(this.category.section_8);
        this.section_9 = this.category.section_9 ? JSON.parse(this.category.section_9) : this.section_9;

      }
      else {
        this.type = 'add';
        this.our_work_title = "";
        this.section_1 = {
          title: "",
          description: ""
        };
        this.ui_ux = {
          title: "",
          subscribe: {
            head: "",
            input_text: ""
          },
          notification: {
            head: "",
            b1_text: "",
            b2_text: ""
          },
          video_link: "",
          content: {
            head: "",
            text: ""
          }
        };
        this.section_2 = {
          title: "",
          sub_title: "",
          data: [
            { ...this.section_2_data }
          ]
        };
        this.section_3 = {
          title: "",
          sub_title: "",
          data: [
            { ...this.section_3_data }
          ]
        };
        this.section_4_data = { head: '', item: [{ ...this.section_4_items }] };
        this.section_4 = { title: '', data: [{ ...this.section_4_data }] },
          this.section_5 = { head: "", text: "", button_text: "" };
        this.section_6 = {
          title: "",
          data: [{ ...this.section_2_data }
          ]
        };
        this.section_7 = [{ ...this.section_7_data }],
          this.section_8 = {
            title: "",
            data: [
              { ...this.section_2_data }
            ]
          };
        this.section_9 = {
          title: "",
          data: [
            { ...this.section_2_data }
          ],
          tail_text: ""
        };
      }
    },
    addItem(data, object) {
      data.push({ ...object });
    },
    removeItem(data, index) {
      data.splice(index, 1);
    },
    previewFiles(event, data, index) {
      this.$store.state.isDetailLoading = true;
      let logo = event.target.files[0];

      dataProcess
        .postData(this.$path + "api/upload-attachment", { 'attachment': logo })
        .then((response) => {
          if (response.status == 200) {
            if (response.data) {
              this.$store.state.isDetailLoading = false;
              data[index] = response.data.data;
            }
          }
        });
    },
  },

  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.getData();
    },
    '$store.state.appDevice': function (newVal, oldVal) {
      this.getData();
    }
  },
  metaInfo: {
    title: "Skiy31 :: Manage category detail",
  },
};
</script>
<style>
.vue-csv-uploader-part-two {
  display: none;
}

.center-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
}

.file-input {
  height: auto;
}

#import-modal .column {
  padding: 0px;
}

.form-fieldset {
  padding: 20px 0;
  max-width: 480px;
  margin: 0 38px;
}
</style>
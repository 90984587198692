<template>
  <div id="app-onboarding" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-navbar-menu"
    data-mobile-item="#home-sidebar-menu-mobile" :class="{ 'is-pushed-full': $store.state.isSubmenuActive }">
    <div class="page-content-wrapper">
      <div class="page-content is-relative">
        <div class="page-title has-text-centered tabs-wrapper">
          <!-- Sidebar Trigger -->
          <sidebar />

          <div class="title-wrap">
            <h1 class="title is-4">Manage Subscribers List </h1>
          </div>
         
          <toolbar />
          
        </div>
        <div class="tabs-inner">
          <div class="tabs">
            <ul>
              <li :class="{ 'is-active': is_confirmed == 1 }" @click="is_confirmed = 1; updateList()"><a><span>Confirmed
                    List</span></a>
              </li>
              <li :class="{ 'is-active': is_confirmed == 0 }" @click="is_confirmed = 0; updateList()"><a><span>Pending
                    List</span></a>
              </li>
              <li class="tab-naver"></li>
            </ul>
          </div>
        </div>

        <div class="datatable-toolbar">
          <div class="select">
            <select class="datatable-filter datatable-select form-control" data-filter="position"
              data-filter-type="default" v-model="perPage" @change="filterData">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          &nbsp;entries per page

          <div class="buttons">
            <button type="button" class="button h-button is-primary ">
              <export-excel class="btn btn-default" :data="newsletters" :fields="json_fields" :name="is_confirmed==1 ?'Confirmed Subscription List.xls':'Pending Subscription List.xls'">
                Download List
              </export-excel>
            </button>
            <div class="control mr-2">
              <input v-model="searchText" @keyup="filterData" class="input custom-text-filter" placeholder="Search..." />
              <div class="form-icon">
                <i data-feather="search"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="page-content-inner">
          <!-- Datatable -->
          <div class="table-wrapper" data-simplebar>
            <table id="users-datatable" class="table is-datatable is-hoverable table-is-bordered">
              <thead>
                <tr>
                  <th>
                    <div class="control">#</div>
                  </th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr data-id="0" v-for="(data, index) in this.newsletters" :key="data.id">
                  <td>
                    <div class="control">
                      {{ parseInt(perPage) * (currentPage - 1) + index + 1 }}
                    </div>
                  </td>
                  <td>
                    {{ data.email }}
                  </td>
                </tr>
              </tbody>
                
            </table>
            <div class="section-placeholder" v-if="this.newsletters.length == 0">
              <div class="placeholder-content">
                <img class="light-image" src="img/illustrations/placeholders/search-4.svg" alt="">
                <img class="dark-image" src="img/illustrations/placeholders/search-4-dark.svg" alt="">
                <h3 class="dark-inverted">No data to show</h3>
                <p>There is currently no data to show in this list.</p>
              </div>
            </div>
          </div>

          <div id="paging-first-datatable" v-if="this.newsletters.length != 0" class="
                                                          pagination
                                                          datatable-pagination
                                                          pagination-datatables
                                                          text-center
                                                        ">
            <pagination v-model="currentPage" :per-page="parseInt(perPage)" :records="total" @paginate="filterData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
import dataProcess from '../../util/dataProcess';
export default {
  name: "home",
  data() {
    return {
      newsletters: [],
      bknewsletters: [],
      buttonloader: [],
      total: 0,
      type: "",
      index: "",
      show: 0,
      currentPage: 1,
      searchText: "",
      perPage: 10,
      tempObject: '',
      is_confirmed: 1,
      aList: [],
      json_fields: {
        'Email': 'email'
      },
    };
  },
  created() {
    this.$store.state.isDetailLoading = false;
  },
  mounted() {
    this.getData();
  },

  beforeMount() { },

  methods: {
    getData() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .getData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/newsletter/list")
        .then((response) => {
          if (response.status == 200) {
            if (response.data) {
              this.aList = response.data.data;
              this.updateList();
            }
            this.$store.state.isDetailLoading = false;
          } else {
            this.total = 0;
          }
        });
    },
    updateList() {
      this.bknewsletters = this.aList.filter(el => el.is_confirmed == this.is_confirmed);
      this.filterData();
    },
    filterData() {
      let searchText = this.searchText.toLowerCase().trim();
      let filterdData;
      if (searchText != "") {
        filterdData = this.bknewsletters.filter(
          (el) =>
            (el.email && el.email.toLowerCase().indexOf(searchText) > -1)
        );
      } else {
        filterdData = this.bknewsletters;
      }


      this.total = filterdData.length;
      if (this.currentPage > 1) {
        let noOfPages = Math.ceil(this.total / parseInt(this.perPage));
        this.currentPage = noOfPages >= this.currentPage ? this.currentPage : noOfPages;
      }
      this.newsletters = filterdData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );

    },
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.getData();
    },
    '$store.state.appDevice': function (newVal, oldVal) {
      this.getData();
    }
  },
  metaInfo: {
    title: "Skiy31 :: View Subscibe List",
  },
};
</script>
<style>
.vue-csv-uploader-part-two {
  display: none;
}

.center-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
}

.file-input {
  height: auto;
}

#import-modal .column {
  padding: 0px;
}

.form-fieldset {
  padding: 20px 0;
  max-width: 480px;
  margin: 0 38px;
}
</style>
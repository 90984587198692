var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper"},[_c('div',{staticClass:"auth-wrapper-inner is-single"},[_vm._m(0),_c('div',{staticClass:"single-form-wrap"},[_c('div',{staticClass:"inner-wrap"},[_c('div',{staticClass:"auth-head"},[_c('div',{staticClass:"form-text",class:_vm.step == 2 ? 'is-hidden' : ''},[_c('h2',[_vm._v("Sign In")]),_c('p',[_vm._v("Welcome back to your account.")])]),_c('div',{staticClass:"form-text",class:_vm.step == 1 ? 'is-hidden' : ''},[_c('h2',[_vm._v("Recover Account")]),_c('p',[_vm._v("Reset your account password.")])])]),_c('div',{staticClass:"form-card"},[_c('form',{staticClass:"login-wrapper",class:_vm.step == 2 ? 'is-hidden' : '',attrs:{"id":"login-form","data-vv-scope":"login-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit('login-form')}}},[_c('div',{staticClass:"login-form"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icon has-validation",class:{ 'has-error': _vm.errors.has('login-form.email') }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"},{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"input",class:{
                      input: true,
                      'is-danger': _vm.errors.has('login-form.email'),
                    },attrs:{"type":"text","name":"email","placeholder":"Email Address *"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_vm._m(1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('login-form.email')),expression:"errors.has('login-form.email')"}],staticClass:"error-text"},[_vm._v(_vm._s(_vm.errors.first("login-form.email")))])])]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icon has-validation",class:{ 'has-error': _vm.errors.has('login-form.password') }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"input",class:{
                      input: true,
                      'is-danger': _vm.errors.has('login-form.password'),
                    },attrs:{"type":"password","placeholder":"Password *","name":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_vm._m(2),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('login-form.password')),expression:"errors.has('login-form.password')"}],staticClass:"error-text"},[_vm._v(_vm._s(_vm.errors.first("login-form.password")))])])]),_c('div',{staticClass:"control login"},[_c('button',{staticClass:"button h-button is-primary is-bold is-fullwidth is-raised\n                                                              ",class:{ 'is-loading': _vm.buttonloader['login-form'] },attrs:{"id":"login-submit","type":"submit"}},[_vm._v(" Login Now ")])])]),_c('div',{staticClass:"forgot-link has-text-centered"},[_c('a',{attrs:{"id":"forgot-link"},on:{"click":function($event){_vm.step = 2}}},[_vm._v("Forgot Password?")])])]),_c('form',{staticClass:"login-wrapper",class:_vm.step == 1 ? 'is-hidden' : '',attrs:{"id":"recover-form","data-vv-scope":"recover-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onForgotSubmit('recover-form')}}},[_c('p',{staticClass:"recover-text"},[_vm._v(" Enter your email and click on the confirm button to reset your password. We'll send you an email detailing the steps to complete the procedure. ")]),_c('div',{staticClass:"login-form"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icon has-validation",class:{ 'has-error': _vm.errors.has('recover-form.email') }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.femail),expression:"femail"},{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"input",class:{
                      input: true,
                      'is-danger': _vm.errors.has('recover-form.email'),
                    },attrs:{"type":"text","name":"email","placeholder":"Email Address *"},domProps:{"value":(_vm.femail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.femail=$event.target.value}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('recover-form.email')),expression:"errors.has('recover-form.email')"}],staticClass:"error-text"},[_vm._v(_vm._s(_vm.errors.first("recover-form.email")))]),_vm._m(3)])])]),_c('div',{staticClass:"button-wrap"},[_c('button',{staticClass:" is-primary\n                                                                button\n                                                                h-button\n                                                                is-solid is-big is-rounded is-lower is-raised is-colored\n                                                              ",class:{ 'is-loading': _vm.buttonloader['recover-form'] },attrs:{"type":"submit"}},[_vm._v(" Confirm ")]),_c('button',{staticClass:"button h-button is-big is-rounded is-lower is-pulled-right",attrs:{"id":"cancel-recover","type":"button"},on:{"click":function($event){_vm.step = 1}}},[_vm._v(" Cancel ")])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-nav"},[_c('div',{staticClass:"left"}),_c('div',{staticClass:"center"},[_c('a',{staticClass:"header-item"},[_c('img',{staticClass:"light-image",attrs:{"src":"img/logos/logo/logo.png","alt":""}}),_c('img',{staticClass:"dark-image",attrs:{"src":"img/logos/logo/logo-light.png","alt":""}})])]),_c('div',{staticClass:"right"},[_c('label',{staticClass:"dark-mode ml-auto"},[_c('input',{attrs:{"type":"checkbox","checked":""}}),_c('span')])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"form-icon"},[_c('i',{staticClass:"lnil lnil-envelope"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"form-icon"},[_c('i',{staticClass:"lnil lnil-lock"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"form-icon"},[_c('i',{staticClass:"lnil lnil-envelope"})])}]

export { render, staticRenderFns }
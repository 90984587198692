<template>
  <div id="app-onboarding" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-navbar-menu"
    data-mobile-item="#home-sidebar-menu-mobile" :class="{ 'is-pushed-full': $store.state.isSubmenuActive }">
    <div class="page-content-wrapper">
      <div class="page-content is-relative">
        <div class="page-title has-text-centered tabs-wrapper">
          <!-- Sidebar Trigger -->
          <sidebar />

          <div class="title-wrap">
            <h1 class="title is-4">Add / Edit Case Study Details</h1>
          </div>
          <toolbar />
        </div>

        <div class="page-content-inner">
          <form id="ourwork-form" data-vv-scope="ourwork-form" v-on:submit.prevent="onSubmit('ourwork-form')">
            <div class="list-view-toolbar stuck-header" :class="{ 'is-stuck': is_stuck }">

              <div class="buttons">
                <div class="control mr-2">

                </div>
                <button type="submit" id="save-button" class="button h-button is-primary is-raised"
                  :class="{ 'is-loading': buttonloader['ourwork-form'] }">

                  <span>Submit</span>
                </button>
              </div>
            </div>
            <div class="columns">
              <div class="column is-12">
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 1</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns">
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in section_1" :key="index">
                            <div class="column is-5">
                              <div class="field">
                                <label>Tool {{ index + 1 }} Name</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('ourwork-form.section_1_item' + index),
                                }">
                                  <input type="text" class="input" :name="'section_1_item' + index" v-model="item.name"
                                    placeholder="" v-validate="'min:2|max:450'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'ourwork-form.item' + index
                                      ),
                                    }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-6">
                              <div class="field">
                                <label>Tool {{ index + 1 }} Icon</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('ourwork-form.section_1_icon' + index),
                                }">

                                  <div class="field is-grouped">
                                    <div class="control">
                                      <div class="file has-name is-fullwidth">
                                        <label class="file-label">
                                          <input class="file-input" type="file"
                                            @change="previewFiles($event, item, 'icon')" placeholder="">
                                          <span class="file-cta">
                                            <span class="file-icon">
                                              <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                            </span>
                                            <span class="file-label">
                                              Choose a file…
                                            </span>
                                          </span>
                                          <span class="file-name light-text">
                                            {{ item.icon ? 'selected' : '' }}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    <img width="100" style="background-color: grey;" class="ml-1" v-if="item.icon"
                                      :src="item.icon">
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_1.length > 1" @click="removeItem(section_1, index)"
                                class="
                                                                                                                                                                                                                                button
                                                                                                                                                                                                                                is-light is-circle
                                                                                                                                                                                                                                hint--bubble hint--primary hint--top
                                                                                                                                                                                                                              "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_1, section_1_data)"
                            class="
                                                                                                                                                                                                                                button is-pulled-right
                                                                                                                                                                                                                                is-light is-circle
                                                                                                                                                                                                                                hint--bubble hint--primary hint--top
                                                                                                                                                                                                                              "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 2 (Intro)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="l-card columns is-multiline">
                      <div class="column is-6">
                        <div class="field">
                          <label>Intro</label>
                          <div class="control has-validation">
                            <trumbowyg v-model="section_2.text" :config="editorConfig">
                            </trumbowyg>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="l-card">
                          <div class="columns is-multiline" v-for="(item, index) in section_2.images" :key="index">
                            <div class="column is-8">
                              <div class="field">
                                <label>Image {{ index + 1 }}</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('ourwork-form.section_1_icon' + index),
                                }">

                                  <div class="field is-grouped">
                                    <div class="control">
                                      <div class="file has-name is-fullwidth">
                                        <label class="file-label">
                                          <input class="file-input" type="file"
                                            @change="previewFiles($event, item, 'url')" placeholder="">
                                          <span class="file-cta">
                                            <span class="file-icon">
                                              <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                            </span>
                                            <span class="file-label">
                                              Choose a file…
                                            </span>
                                          </span>
                                          <span class="file-name light-text">
                                            {{ item.url != '' ? 'selected' : '' }}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    <img width="100" style="background-color: grey;" class="ml-1" v-if="item.url && item.url.split('.')[5]!='mp4'"
                                      :src="item.url">
                                    <img width="100" style="background-color: grey;" class="ml-1" v-if="item.url && item.url.split('.')[5]=='mp4'"
                                      src="img/video.jpg">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_2.images.length > 1" @click="removeItem(section_2.images, index)"
                                class="
                                                                                                                                                                                                                                button
                                                                                                                                                                                                                                is-light is-circle
                                                                                                                                                                                                                                hint--bubble hint--primary hint--top
                                                                                                                                                                                                                              "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>

                          <a @click="addItem(section_2.images, section_2_data)"
                            class="
                                                                                                                                                                                                                                button is-pulled-right
                                                                                                                                                                                                                                is-light is-circle
                                                                                                                                                                                                                                hint--bubble hint--primary hint--top
                                                                                                                                                                                                                              "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 3 (Bullet List)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card columns is-multiline ml-1">
                          <div class="column is-6">
                            <div class="field">
                              <label>Section 3 Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('ourwork-form.section_3_title'),
                              }">
                                <input type="text" class="input" name="section_3_title" v-model="section_3.title"
                                  placeholder="" v-validate="'min:2|max:350'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'ourwork-form.section_3_title'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label>Section 3 Sub Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('ourwork-form.section_3_sub_content'),
                              }">
                                <input type="text" class="input" name="section_3_sub_content"
                                  v-model="section_3.sub_content" placeholder="" v-validate="'min:2|max:500'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'ourwork-form.section_3_sub_content'
                                    ),
                                  }" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in section_3.data" :key="index">

                            <div class="column is-5">
                              <div class="field">
                                <label>List {{ index + 1 }} Text</label>
                                <div class="control has-validation">
                                  <trumbowyg v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_3.data.length > 1" @click="removeItem(section_3.data, index)"
                                class="
                                                                                                                                                                                                                                button
                                                                                                                                                                                                                                is-light is-circle
                                                                                                                                                                                                                                hint--bubble hint--primary hint--top
                                                                                                                                                                                                                              "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_3.data, section_3_data)"
                            class="
                                                                                                                                                                                                                                button is-pulled-right
                                                                                                                                                                                                                                is-light is-circle
                                                                                                                                                                                                                                hint--bubble hint--primary hint--top
                                                                                                                                                                                                                              "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-card" v-for="(item_h, i) in section_4" :key="i">
                    <div class="demo-title">
                      <h3 class="title is-thin is-5">Section 4 (Challenges) {{ i + 1 }}</h3>
                    </div>
                    <div class="card-inner">
                      <div class="columns is-multiline">
                        <div class="column is-12">
                          <div class="l-card">
                            <div class="column is-6">
                              <div class="field">
                                <label>Title</label>
                                <div class="control has-validation"
                                  :class="{ 'has-error': errors.has('ourwork-form.section_4_title' + i) }">
                                  <input type="text" class="input" :name="'section_4_title' + i" v-model="item_h.title"
                                    placeholder="" v-validate="'min:2|max:450'"
                                    :class="{ input: true, 'is-danger': errors.has('ourwork-form.section_4_title' + i) }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-6">
                              <div class="field">
                                <label>List Type </label>
                                <div class="control has-validation"
                                  :class="{ 'has-error': errors.has('ourwork-form.section_4_list_type' + i) }">
                                  <div class="select">
                                    <select class="datatable-filter datatable-select form-control"
                                      :name="'section_4_list_type' + i" v-model="item_h.list_type">
                                      <option value="bullet">Bullet List</option>
                                      <option value="number">Number List</option>
                                      <option value="arrow">Arrow List</option>
                                      <option value="number_image">Number + image List</option>
                                      <option value="countdown_list">Count Down List</option>
                                      <option value="alphabet">alphabet List</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="column is-6">
                              <div class="field">
                                <label>Section 4 Content </label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_4_sub_content'),
                                }">
                                  <trumbowyg v-model="item_h.sub_content" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="column is-12">
                          <div class="l-card mt-2">
                            <div class="columns is-multiline" v-for="(item, index) in  item_h.data" :key="i + index">
                              <div class="column is-3">
                                <div class="field">
                                  <label>List {{ index + 1 }} Title</label>
                                  <div class="control has-validation"
                                    :class="{ 'has-error': errors.has('category-form.section_4_item' + i + index) }">
                                    <input type="text" class="input" :name="'section_4_item' + i + index"
                                      v-model="item.head" placeholder="" v-validate="'min:2|max:350'"
                                      :class="{ input: true, 'is-danger': errors.has('category-form.item' + i + index) }" />
                                  </div>
                                </div>
                              </div>
                              <div class="column is-3">
                                <div class="field">
                                  <label>List {{ index + 1 }} Arrow text</label>
                                  <div class="control has-validation"
                                    :class="{ 'has-error': errors.has('category-form.section_4_arrow_text' + i + index) }">
                                    <input type="text" class="input" :name="'section_4_arrow_text' + i + index"
                                      v-model="item.arrow_text" placeholder="" v-validate="'min:2|max:450'"
                                      :class="{ input: true, 'is-danger': errors.has('category-form.arrow_text' + index) }" />
                                  </div>
                                </div>
                              </div>
                              <div class="column is-5">
                                <div class="field">
                                  <label>List {{ index + 1 }} Text</label>
                                  <div class="control has-validation">
                                    <trumbowyg v-model="item.text" :config="editorConfig">
                                    </trumbowyg>
                                  </div>
                                </div>
                              </div>
                              <div class="column is-1">
                                <a v-if="item_h.data.length > 1" @click="removeItem(item_h.data, index)"
                                  class="button is-light is-circle hint--bubble hint--primary hint--top"
                                  data-hint="Remove Item">
                                  <i class="lnil lnil-close"></i>
                                </a>
                              </div>
                            </div>
                            <a @click="addItem(item_h.data, section_4_data)"
                              class="button is-pulled-right is-light is-circle hint--bubble hint--primary hint--top"
                              data-hint="Add">
                              <span class="icon is-small">
                                <span class="fa-fw select-all fas"></span>
                              </span>
                            </a>
                          </div>
                        </div>
                        <div class="column is-6">
                          <div class="field">
                            <label>Section 4 Tail Text </label>
                            <div class="control has-validation">
                              <trumbowyg v-model="item_h.tail_text" :config="editorConfig">
                              </trumbowyg>
                            </div>
                          </div>
                        </div>
                        <div class="column is-12">
                          <div class="l-card mt-2">
                            <div class="columns is-multiline" v-for="( item_2, index2 ) in  item_h.data_2"
                              :key="i + index2">
                              <div class="column is-3">
                                <div class="field">
                                  <label>List {{ index2 + 1 }} Count</label>
                                  <div class="control has-validation" :class="{
                                    'has-error': errors.has('category-form.section_4_item_2' + i + index2),
                                  }
                                    ">
                                    <input type="text" class="input" :name="'section_4_item_2' + i + index2"
                                      v-model="item_2.count" placeholder="" v-validate="'max:450'" :class="{
                                        input: true,
                                        'is-danger': errors.has(
                                          'category-form.item_2' + i + index2
                                        ),
                                      }
                                        " />
                                  </div>
                                </div>
                              </div>
                              <div class="column is-5">
                                <div class="field">
                                  <label>List {{ index2 + 1 }} Text</label>
                                  <div class="control has-validation">
                                    <trumbowyg v-model="item_2.text" :config="editorConfig">
                                    </trumbowyg>
                                  </div>
                                </div>
                              </div>
                              <div class="column is-1">
                                <a v-if="item_h.data_2.length > 1" @click="removeItem(item_h.data_2, index2)"
                                  class="
                                                                                                                                                                                                                  button
                                                                                                                                                                                                                  is-light is-circle
                                                                                                                                                                                                                  hint--bubble hint--primary hint--top
                                                                                                                                                                                                                "
                                  data-hint="Remove Item">

                                  <i class="lnil lnil-close"></i>
                                </a>
                              </div>
                            </div>
                            <a @click="addItem(item_h.data_2, section_8_data)"
                              class="
                                                                                                                                                                                                                  button is-pulled-right
                                                                                                                                                                                                                  is-light is-circle
                                                                                                                                                                                                                  hint--bubble hint--primary hint--top
                                                                                                                                                                                                                "
                              data-hint="Add">
                              <span class="icon is-small">
                                <span class="fa-fw select-all fas"></span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a v-if="section_4.length > 1" @click="removeItem(section_4, i)"
                      class="button is-dark is-circle hint--bubble hint--primary hint--top is-pulled-right"
                      data-hint="Remove Item">
                      <i class="lnil lnil-close"></i>
                    </a>
                  </div>
                  <a @click="addItem(section_4, section_4_loop)"
                    class="button is-pulled-right is-light is-circle hint--bubble hint--primary hint--top"
                    data-hint="Add">
                    <span class="icon is-small">
                      <span class="fa-fw select-all fas"></span>
                    </span>
                  </a>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 5 (Custom Html)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns">
                      <div class="column is-6">
                        <div class="field">
                          <label>Text</label>
                          <div class="control has-validation">
                            <trumbowyg v-model="section_5" :config="editorConfig"></trumbowyg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 6 (Developing)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card">
                          <div class="column is-6">
                            <div class="field">
                              <label>Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('ourwork-form.section_6_title'),
                              }
                                ">
                                <input type="text" class="input" name="section_6_title" v-model="section_6.title"
                                  placeholder="" v-validate="'min:2|max:450'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'ourwork-form.section_6_title'
                                    ),
                                  }
                                    " />
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label>List Type </label>
                              <div class="control has-validation"
                                :class="{ 'has-error': errors.has('ourwork-form.section_6_list_type') }">
                                <div class="select">
                                  <select class="datatable-filter datatable-select form-control"
                                    name="section_6_list_type" v-model="section_6.list_type">
                                    <option value="bullet">Bullet List</option>
                                    <option value="number">Number List</option>
                                    <option value="arrow">Arrow List</option>
                                    <option value="number_image">Number + image List</option>
                                    <option value="countdown_list">Count Down List</option>
                                    <option value="alphabet">alphabet List</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="( item, index ) in  section_6.data " :key="index">
                            <div class="column is-4">
                              <div class="field">
                                <label>List {{ index + 1 }} Text</label>
                                <div class="control has-validation">
                                  <trumbowyg v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label>Image {{ index + 1 }}</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('ourwork-form.section_6_image' + index),
                                }
                                  ">

                                  <div class="field is-grouped">
                                    <div class="control">
                                      <div class="file has-name is-fullwidth">
                                        <label class="file-label">
                                          <input class="file-input" type="file"
                                            @change="previewFiles($event, item, 'image')" placeholder="">
                                          <span class="file-cta">
                                            <span class="file-icon">
                                              <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                            </span>
                                            <span class="file-label">
                                              Choose a file…
                                            </span>
                                          </span>
                                          <span class="file-name light-text">
                                            {{ item.image ? 'selected' : '' }}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    <img width="100" style="background-color: grey;" class="ml-1" v-if="item.image"
                                      :src="item.image">
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="column is-3">
                              <div class="field">
                                <label>List {{ index + 1 }} Tail text</label>
                                <div class="control has-validation">
                                  <trumbowyg v-model="item.tail_text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_6.data.length > 1" @click="removeItem(section_6.data, index)"
                                class="
                                                                                                                                                                                                                  button
                                                                                                                                                                                                                  is-light is-circle
                                                                                                                                                                                                                  hint--bubble hint--primary hint--top
                                                                                                                                                                                                                "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_6.data, section_6_data)"
                            class="
                                                                                                                                                                                                                  button is-pulled-right
                                                                                                                                                                                                                  is-light is-circle
                                                                                                                                                                                                                  hint--bubble hint--primary hint--top
                                                                                                                                                                                                                "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 7</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns">
                      <div class="column is-6">
                        <div class="field">
                          <label>Text</label>
                          <div class="control has-validation">
                            <trumbowyg v-model="section_7" :config="editorConfig"></trumbowyg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 8</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card">
                          <div class="column is-6">
                            <div class="field">
                              <label>Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('ourwork-form.section_8_title'),
                              }
                                ">
                                <input type="text" class="input" name="section_8_title" v-model="section_8.title"
                                  placeholder="" v-validate="'min:2|max:450'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'ourwork-form.section_8_title'
                                    ),
                                  }
                                    " />
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label>Description</label>
                              <div class="control has-validation">
                                <trumbowyg v-model="section_8.description" :config="editorConfig">
                                </trumbowyg>
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label>List Type </label>
                              <div class="control has-validation"
                                :class="{ 'has-error': errors.has('ourwork-form.section_8_list_type') }">
                                <div class="select">
                                  <select class="datatable-filter datatable-select form-control"
                                    name="section_8_list_type" v-model="section_8.list_type">
                                    <option value="bullet">Bullet List</option>
                                    <option value="number">Number List</option>
                                    <option value="arrow">Arrow List</option>
                                    <option value="number_image">Number + image List</option>
                                    <option value="countdown_list">Count Down List</option>
                                    <option value="alphabet">alphabet List</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="( item, index ) in  section_8.data " :key="index">
                            <div class="column is-3">
                              <div class="field">
                                <label>List {{ index + 1 }} Count</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_8_item' + index),
                                }
                                  ">
                                  <input type="text" class="input" :name="'section_8_item' + index" v-model="item.count"
                                    placeholder="" v-validate="'max:450'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'category-form.item' + index
                                      ),
                                    }
                                      " />
                                </div>
                              </div>
                            </div>
                            <div class="column is-5">
                              <div class="field">
                                <label>List {{ index + 1 }} Text</label>
                                <div class="control has-validation">
                                  <trumbowyg v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_8.data.length > 1" @click="removeItem(section_8.data, index)"
                                class="
                                                                                                                                                                                                                  button
                                                                                                                                                                                                                  is-light is-circle
                                                                                                                                                                                                                  hint--bubble hint--primary hint--top
                                                                                                                                                                                                                "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_8.data, section_8_data)"
                            class="
                                                                                                                                                                                                                  button is-pulled-right
                                                                                                                                                                                                                  is-light is-circle
                                                                                                                                                                                                                  hint--bubble hint--primary hint--top
                                                                                                                                                                                                                "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 9</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns">
                      <div class="column is-6">
                        <div class="field">
                          <label>Text</label>
                          <div class="control has-validation">
                            <trumbowyg v-model="section_9" :config="editorConfig"></trumbowyg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 10 (Bullet List)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card columns is-multiline ml-1">
                          <div class="column is-6">
                            <div class="field">
                              <label>Section 10 Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_10_title'),
                              }
                                ">
                                <input type="text" class="input" name="section_10_title" v-model="section_10.title"
                                  placeholder="" v-validate="'min:2|max:450'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_10_title'
                                    ),
                                  }
                                    " />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="( item, index ) in  section_10.data " :key="index">
                            <div class="column is-2">
                              <div class="field">
                                <label>List {{ index + 1 }} Title</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_10_item' + index),
                                }
                                  ">
                                  <input type="text" class="input" :name="'section_10_item' + index" v-model="item.head"
                                    placeholder="" v-validate="'min:2|max:450'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'category-form.item' + index
                                      ),
                                    }
                                      " />
                                </div>
                              </div>
                            </div>
                            <div class="column is-5">
                              <div class="field">
                                <label>List {{ index + 1 }} Text</label>
                                <div class="control has-validation">
                                  <trumbowyg v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label>List {{ index + 1 }} Icon</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_10_item_logo' + index),
                                }
                                  ">

                                  <div class="field is-grouped">
                                    <div class="control">
                                      <div class="file has-name is-fullwidth">
                                        <label class="file-label">
                                          <input class="file-input" type="file"
                                            @change="previewFiles($event, item, 'icon')" placeholder="">
                                          <span class="file-cta">
                                            <span class="file-icon">
                                              <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                            </span>
                                            <span class="file-label">
                                              Choose a file…
                                            </span>
                                          </span>
                                          <span class="file-name light-text">
                                            {{ item.icon ? 'selected' : '' }}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    <img width="100" style="background-color: grey;" class="ml-1" v-if="item.icon"
                                      :src="item.icon">
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_10.data.length > 1" @click="removeItem(section_10.data, index)"
                                class="
                                                                                                                                                                                            button
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_10.data, section_10_data)"
                            class="button is-pulled-right
                                                                                                                                                                                            is-light is-circle
                                                                                                                                                                                            hint--bubble hint--primary hint--top
                                                                                                                                                                                          "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 11 (FAQ)</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="l-card">
                          <div class="column is-6">
                            <div class="field">
                              <label>FAQ Section Title</label>
                              <div class="control has-validation" :class="{
                                'has-error': errors.has('category-form.section_11_title'),
                              }
                                ">
                                <input type="text" class="input" name="section_11_title" v-model="section_11.title"
                                  placeholder="" v-validate="'min:2|max:450'" :class="{
                                    input: true,
                                    'is-danger': errors.has(
                                      'category-form.section_11_title'
                                    ),
                                  }
                                    " />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="( item, index ) in  section_11.data " :key="index">
                            <div class="column is-4">
                              <div class="field">
                                <label>Question {{ index + 1 }}</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('category-form.section_11_item' + index),
                                }
                                  ">
                                  <input type="text" class="input" :name="'section_11_item' + index" v-model="item.head"
                                    placeholder="" v-validate="'min:2|max:450'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'category-form.item' + index
                                      ),
                                    }
                                      " />
                                </div>
                              </div>
                            </div>
                            <div class="column is-7">
                              <div class="field">
                                <label>Answer {{ index + 1 }}</label>
                                <div class="control has-validation">
                                  <trumbowyg v-model="item.text" :config="editorConfig">
                                  </trumbowyg>
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_11.data.length > 1" @click="removeItem(section_11.data, index)"
                                class="
                                                                                                                                                                                          button
                                                                                                                                                                                          is-light is-circle
                                                                                                                                                                                          hint--bubble hint--primary hint--top
                                                                                                                                                                                        "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_11.data, section_2_data)"
                            class="
                                                                                                                                                                                          button is-pulled-right
                                                                                                                                                                                          is-light is-circle
                                                                                                                                                                                          hint--bubble hint--primary hint--top
                                                                                                                                                                                        "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Website Links</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns">
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in website_link" :key="index">
                            <div class="column is-6">
                              <div class="field">
                                <label>Title {{ index + 1 }} Name</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('ourwork-form.website_link_item' + index),
                                }">
                                  <input type="text" class="input" :name="'website_link_item' + index"
                                    v-model="item.title" placeholder="" v-validate="'min:2|max:450'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'ourwork-form.item' + index
                                      ),
                                    }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-6">
                              <div class="field">
                                <label>Link {{ index + 1 }} </label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('ourwork-form.website_link_link' + index),
                                }">
                                  <input type="text" class="input" :name="'website_link_link' + index" v-model="item.link"
                                    placeholder="" v-validate="'min:2|max:550'" :class="{
                                      input: true,
                                      'is-danger': errors.has(
                                        'ourwork-form.item' + index
                                      ),
                                    }" />
                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="website_link.length > 1" @click="removeItem(website_link, index)"
                                class="
                                                                                                                                                                                                                                button
                                                                                                                                                                                                                                is-light is-circle
                                                                                                                                                                                                                                hint--bubble hint--primary hint--top
                                                                                                                                                                                                                              "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(website_link, website_link_items)"
                            class="
                                                                                                                                                                                                                                button is-pulled-right
                                                                                                                                                                                                                                is-light is-circle
                                                                                                                                                                                                                                hint--bubble hint--primary hint--top
                                                                                                                                                                                                                              "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-card">
                  <div class="demo-title">
                    <h3 class="title is-thin is-5">Section 12</h3>
                  </div>
                  <div class="card-inner">
                    <div class="columns">
                      <div class="column is-12">
                        <div class="l-card mt-2">
                          <div class="columns is-multiline" v-for="(item, index) in section_12" :key="index">

                            <div class="column is-6">
                              <div class="field">
                                <label>Image {{ index + 1 }}</label>
                                <div class="control has-validation" :class="{
                                  'has-error': errors.has('ourwork-form.section_12_icon' + index),
                                }">

                                  <div class="field is-grouped">
                                    <div class="control">
                                      <div class="file has-name is-fullwidth">
                                        <label class="file-label">
                                          <input class="file-input" type="file"
                                            @change="previewFiles($event, item, 'image')" placeholder="">
                                          <span class="file-cta">
                                            <span class="file-icon">
                                              <i class="lnil lnil-lg lnil-cloud-upload"></i>
                                            </span>
                                            <span class="file-label">
                                              Choose a file…
                                            </span>
                                          </span>
                                          <span class="file-name light-text">
                                            {{ item.image ? 'selected' : '' }}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    <img width="100" style="background-color: grey;" class="ml-1" v-if="item.image"
                                      :src="item.image">
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="column is-1">
                              <a v-if="section_12.length > 1" @click="removeItem(section_12, index)"
                                class="
                                                                                                                                                                                                                                button
                                                                                                                                                                                                                                is-light is-circle
                                                                                                                                                                                                                                hint--bubble hint--primary hint--top
                                                                                                                                                                                                                              "
                                data-hint="Remove Item">

                                <i class="lnil lnil-close"></i>
                              </a>
                            </div>
                          </div>
                          <a @click="addItem(section_12, section_12_data)"
                            class="
                                                                                                                                                                                                                                button is-pulled-right
                                                                                                                                                                                                                                is-light is-circle
                                                                                                                                                                                                                                hint--bubble hint--primary hint--top
                                                                                                                                                                                                                              "
                            data-hint="Add">
                            <span class="icon is-small">
                              <span class="fa-fw select-all fas"></span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
import dataProcess from '../../util/dataProcess';
export default {
  name: "CategoryDetail",
  components: {

  },
  data() {
    return {
      is_stuck: false,
      editorConfig: {
        lang: this.$store.state.appLocale.langId,
        autogrow: true,
        semantic: false,
        removeformatPasted: true,
        tagsToKeep: ['div', 'img'],
        btns: [
          ['bold', 'italic'], ['formatting'], ['link'], ['viewHTML'], ['fullscreen']
        ]
      },
      buttonloader: [],
      ourwork: {
        section_1: "",
        section_2: "",
        section_3: "",
        section_4: "",
        section_5: "",
        section_6: "",
        section_7: "",
        section_8: "",
        section_9: "",
        section_10: "",
        section_11: "",
        section_12: "",
        website_link: "",
        lang_id: this.$store.state.appLocale.langId,
        device: this.$store.state.appDevice.device,
        our_work_id: ""
      },
      section_1: [],
      section_1_data: { name: '', icon: '' },
      section_2: {
        text: "",
        images: [
        ]
      },
      section_2_data: { url: '' },
      section_3: {
        title: "",
        sub_content: "",
        data: [
        ]
      },
      section_3_data: { text: '' },
      section_4: [],
      section_4_data: { head: '', item: '', arrow_text: '' },
      section_4_loop: { title: '', list_type: '', sub_content: "", data: [{ head: '', item: '', arrow_text: '' }], tail_text: "", data_2: [{ count: '', text: '' }] },
      section_5: '',
      section_6: {
        title: "",
        list_type: "number",
        data: [
        ]
      },
      section_6_data: { text: '', image: '', tail_text:"" },
      section_7: '',
      section_8: {
        title: "",
        list_type: "countdown_list",
        description: "",
        data: [
        ]
      },
      section_8_data: { count: '', text: '' },
      section_9: '',
      section_10: {
        title: "",
        sub_content: "",
        data: [
        ]
      },
      section_10_data: { icon: '', head: '', text: '' },
      section_11: {
        title: "",
        data: [
        ]
      },
      section_11_data: { head: '', text: '' },
      website_link: [],
      website_link_items: { title: '', link: '' },
      section_12: [],
      section_12_data: { image: '' },
      index: "",
      type: 'add',
      our_work_id: ""
    };
  },
  created() {
    this.$store.state.isDetailLoading = false;
    this.our_work_id = this.$route.params.id;
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.getData();
  },

  beforeMount() { },

  methods: {
    handleScroll(event) {
      let header = document.getElementsByClassName("stuck-header")[0];
      if (header) {
        this.is_stuck = window.scrollY > 80 ? true : false;
      }
    },
    getData() {
      this.$store.state.isDetailLoading = true;
      dataProcess
        .getData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/ourwork/detail/" + this.our_work_id)
        .then((response) => {
          if (response.status == 200) {
            this.intializeSections(response.data);
            this.$store.state.isDetailLoading = false;
          }
        });
    },

    onSubmit(scope) {
      this.$validator.validateAll(scope).then((result) => {
        this.$notyf.dismissAll();
        if (result) {

          this.buttonloader[scope] = true;
          this.ourwork.section_1 = (this.section_1.length > 0 && this.section_1[0].name != '') ? JSON.stringify(this.section_1) : '';
          this.ourwork.website_link = (this.website_link.length > 0 && this.website_link[0].title != '') ? JSON.stringify(this.website_link) : '';
          this.ourwork.section_2 = (this.section_2.text != '') ? JSON.stringify(this.section_2) : '';
          this.ourwork.section_3 = (this.section_3.title != '' || (this.section_3.data.length > 0 && this.section_3.data[0].text != '')) ? JSON.stringify(this.section_3) : '';
          this.ourwork.section_4 = this.section_4.length > 0 ? JSON.stringify(this.section_4) : '';
          this.ourwork.section_5 = (this.section_5 != '') ? JSON.stringify(this.section_5) : '';
          this.ourwork.section_6 = (this.section_6.title != '' || (this.section_6.data.length > 0 && this.section_6.data[0].text != '')) ? JSON.stringify(this.section_6) : '';
          this.ourwork.section_7 = (this.section_7 != '') ? JSON.stringify(this.section_7) : '';
          this.ourwork.section_8 = (this.section_8.title != '' || (this.section_8.data.length > 0 && this.section_8.data[0].count != '')) ? JSON.stringify(this.section_8) : '';
          this.ourwork.section_9 = (this.section_9 != '') ? JSON.stringify(this.section_9) : '';
          this.ourwork.section_10 = (this.section_10.title != '' || (this.section_10.data.length > 0 && this.section_10.data[0].head != '')) ? JSON.stringify(this.section_10) : '';
          this.ourwork.section_11 = (this.section_11.title != '' || (this.section_11.data.length > 0 && this.section_11.data[0].head != '')) ? JSON.stringify(this.section_11) : '';
          this.ourwork.section_12 = (this.section_12.length > 0 && this.section_12[0].image != '') ? JSON.stringify(this.section_12) : '';
          this.ourwork.our_work_id = this.our_work_id;
          if (this.ourwork.section_1 != '' || this.ourwork.website_link != '' || this.ourwork.section_2 != '' || this.ourwork.section_3 != '' || this.ourwork.section_4 != '' || this.ourwork.section_5 != '' || this.ourwork.section_6 != '' || this.ourwork.section_7 != '' || this.ourwork.section_8 != '' || this.ourwork.section_9 != '' || this.ourwork.section_10 != '' || this.ourwork.section_11 != '' || this.ourwork.section_12 != '') {
            if (this.type == "add") {
              this.$store.state.isDetailLoading = true;
              dataProcess
                .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/ourwork/store-detail", this.ourwork)
                .then((response) => {
                  if (response.status == 200) {
                    if (response.data) {
                      this.buttonloader[scope] = false;
                      this.$store.state.isDetailLoading = false;
                      this.$notyf.success("Category Detail added successfully");
                      this.getData();
                    } else {
                      this.buttonloader[scope] = false;
                      this.$store.state.isDetailLoading = false;
                      this.$notyf.error("Error in inserting data");
                    }
                  }
                  if (response.status == 422 || response.status == 500) {
                    this.buttonloader[scope] = false;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error(response.data);
                  }
                });
            } else {
              this.$store.state.isDetailLoading = true;
              let postData = { ...this.ourwork };
              dataProcess
                .postData(this.$path + "api/" + this.$store.state.appLocale.langId + "/d" + this.$store.state.appDevice.id + "/ourwork/update-detail", postData)
                .then((response) => {
                  if (response.status == 200) {
                    if (response.data) {
                      this.intializeSections(response.data);
                      this.buttonloader[scope] = false;
                      this.$store.state.isDetailLoading = false;
                      this.$notyf.success("Category deatil updated successfully");
                    } else {
                      this.buttonloader[scope] = false;
                      this.$store.state.isDetailLoading = false;
                      this.$notyf.error("Error in inserting data");
                    }
                  }
                  if (response.status == 422 || response.status == 500) {
                    this.buttonloader[scope] = false;
                    this.show = 0;
                    this.$store.state.isDetailLoading = false;
                    this.$notyf.error(response.data);
                  }
                });
            }
          }
          else {
            this.buttonloader[scope] = false;
            this.$notyf.error("Please enter atleast one section details");
          }
        } else {
          this.buttonloader[scope] = false;
          this.$notyf.error("Correct form errors!!");
        }
      });
    },
    intializeSections(data) {
      if (data.data.length > 0) {
        this.type = 'edit';
        this.ourwork = data.data[0];
        this.section_1 = this.ourwork.section_1 ? JSON.parse(this.ourwork.section_1) : [{ ...this.section_1_data }];
        this.website_link = (this.ourwork.website_link && this.ourwork.website_link != "") ? JSON.parse(this.ourwork.website_link) : [{ ...this.website_link_items }];
        this.section_2 = JSON.parse(this.ourwork.section_2);
        this.section_3 = this.ourwork.section_3 ? JSON.parse(this.ourwork.section_3) : {
          title: "",
          sub_content: "",
          data: [{ ...this.section_3_data }
          ]
        };
        this.section_4 = JSON.parse(this.ourwork.section_4);
        this.section_5 = JSON.parse(this.ourwork.section_5);
        this.section_6 = this.ourwork.section_6 ? JSON.parse(this.ourwork.section_6) : {
          title: "",
          list_type: "number",
          data: [{ ...this.section_6_data }
          ]
        };
        this.section_7 = JSON.parse(this.ourwork.section_7);
        this.section_8 = this.ourwork.section_8 ? JSON.parse(this.ourwork.section_8) : {
          title: "",
          list_type: "countdown_list",
          description: "",
          data: [
            { ...this.section_8_data }
          ]
        };
        this.section_9 = JSON.parse(this.ourwork.section_9);
        this.section_10 = JSON.parse(this.ourwork.section_10);
        this.section_10 = this.section_10 != null ? this.section_10 : {
          title: "",
          data: [
            { ...this.section_10_data }
          ]
        }
        this.section_11 = JSON.parse(this.ourwork.section_11);
        this.section_11 = this.section_11 != null ? this.section_11 : {
          title: "",
          data: [
            { ...this.section_11_data }
          ]
        }
        this.section_12 = this.ourwork.section_12 ? JSON.parse(this.ourwork.section_12) : [{ ...this.section_12_data }];
      }
      else {
        this.type = 'add';
        this.section_1 = [{ ...this.section_1_data }];
        this.section_2 = {
          text: "",
          images: [
            { ... this.section_2_data }
          ]
        };
        this.section_3 = {
          title: "",
          sub_content: "",
          data: [{ ...this.section_3_data }
          ]
        };
        this.section_3_data = { text: '' };
        this.section_4_loop = { title: '', sub_content: "", list_type: '', data: [{ ...this.section_4_data }], tail_text: "", data_2: [{ ...this.section_8_data }] };
        this.section_4 = [this.section_4_loop];
        this.section_5 = '';
        this.section_6 = {
          title: "",
          list_type: "number",
          data: [{ ...this.section_6_data }
          ]
        },
          this.section_7 = '',
          this.section_8 = {
            title: "",
            description: "",
            list_type: "countdown_list",
            data: [
              { ...this.section_8_data }
            ]
          };
        this.section_9 = '';
        this.section_10 = {
          title: "",
          data: [
            { ...this.section_10_data }
          ]
        };
        this.section_11 = {
          title: "",
          data: [
            { ...this.section_11_data }
          ]
        };
        this.section_12 = [{ ...this.section_12_data }];
        this.website_link = [{ ...this.website_link_items }];
      }
    },
    addItem(data, object) {
      data.push({ ...object });
    },
    removeItem(data, index) {
      data.splice(index, 1);
    },
    previewFiles(event, data, index) {
      this.$store.state.isDetailLoading = true;
      let logo = event.target.files[0];

      dataProcess
        .postData(this.$path + "api/upload-attachment", { 'attachment': logo })
        .then((response) => {
          if (response.status == 200) {
            if (response.data) {
              this.$store.state.isDetailLoading = false;
              data[index] = response.data.data;
            }
          }
        });
    },
  },

  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.getData();
    },
    '$store.state.appDevice': function (newVal, oldVal) {
      this.getData();
    }
  },
  metaInfo: {
    title: "Skiy31 :: Manage ourwork detail",
  },
};
</script>
<style>
.vue-csv-uploader-part-two {
  display: none;
}

.center-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
}

.file-input {
  height: auto;
}

#import-modal .column {
  padding: 0px;
}

.form-fieldset {
  padding: 20px 0;
  max-width: 480px;
  margin: 0 38px;
}
</style>
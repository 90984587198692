 <template>
  <!-- Sidebar Trigger -->
  <div
    class="huro-hamburger nav-trigger push-resize"
    data-sidebar="layouts-sidebar"
  >
    <span class="menu-toggle has-chevron">
      <span class="icon-box-toggle" @click="toggleSubMenu()" :class="{ 'active': $store.state.isSubmenuActive }">
        <span class="rotate">
          <i class="icon-line-top"></i>
          <i class="icon-line-center"></i>
          <i class="icon-line-bottom"></i>
        </span>
      </span>
    </span>
  </div>
</template>
        
<script>
export default {
  name: "Sidebar",
  created() {
  
  },
  mounted() {
    
  },


  methods: {
   toggleSubMenu()
    {
      if(this.$store.state.subMenu==1)
      {
        this.$store.state.isSubmenuActive = !this.$store.state.isSubmenuActive;
      }
    },
  },
};
</script>